<template>
  <v-dialog
    v-model="appApontamento.showModal"
    transition="dialog-bottom-transition"
    fullscreen
  >
    <v-card height="120" color="#E0E0E0">
      <v-toolbar color="primary" >
        <v-btn
          @click="onVoltarApontamento"
          color="secondary"
          variant="elevated"
          prepend-icon="mdi-arrow-left-circle"
          >VOLTAR</v-btn
        >

        <v-toolbar-title style="font-size: 13px; font-weight: bold">{{
          appApontamento.titulo_modal
        }}</v-toolbar-title>

        <v-spacer></v-spacer>
      </v-toolbar>

      <v-row class="mt-0 pt-0">
        <v-col cols="12" class="mt-0 pt-0">
          <v-card color="#EEEEEE">
            <v-toolbar
              :color="colorStatusApontamento"
              class="toolbar-realtime"
              height="40"
              id="v-toolbar_production"
            >
            </v-toolbar>

            <v-row aligin="center">
              <v-col cols="12" sm="5" md="5">
                <div class="buttons mt-2 m-2">
                  <v-row justify="center" class="mt-2">
                    <v-card-text class="text-lg-body-1 py-2 px-0 text-xl-h6 text-center font-weight-bold">ALTERAR STATUS DA PRODUÇÃO:</v-card-text>
                  </v-row>
                  <v-row class="pr-0 ml-n1 pl-1 pt-0 pb-1" >
                    <v-col class="px-0 py-1" cols="6">
                      <v-btn
                        id="btn-style"
                        color="#43A047"
                        :width="rectangleButtons.width" :height="rectangleButtons.height" size="small" 
                        stacked
                        @click="click_produzindo"
                      >
                        <v-card-text class="text-body-1 text-sm-caption text-xs-caption font-weight-bold">PRODUZINDO</v-card-text> 
                      </v-btn>
                    </v-col>

                    <v-col class="px-0 py-1" cols="6">
                      <v-btn
                        id="btn-style"
                        color="#1E88E5"
                        :width="rectangleButtons.width" :height="rectangleButtons.height" size="small" 
                        stacked
                        @click="click_manutencao"
                      >
                        <v-card-text class="text-body-1 text-sm-caption text-xs-caption font-weight-bold ">MANUTENÇÃO</v-card-text> 
                      </v-btn>
                    </v-col>

                    <v-col class="px-0 py-1" cols="6">
                      <v-btn
                        id="btn-style"
                        color="#F9A825"
                        :width="rectangleButtons.width" :height="rectangleButtons.height" size="small" 
                        stacked
                        @click="click_setup"
                      >
                        <v-card-text class="text-body-1 text-sm-caption text-xs-caption font-weight-bold">SETUP</v-card-text> 
                      </v-btn>
                    </v-col>
                    <v-col class="px-0 py-1" cols="6">
                      <v-btn
                        id="btn-style"
                        color="#D50000"
                        :width="rectangleButtons.width" :height="rectangleButtons.height" size="small" 
                        stacked
                        @click="motivo_de_parada"
                      >
                        <v-card-text class="text-body-1 text-sm-caption text-xs-caption font-weight-bold">PARADO</v-card-text> 
                      </v-btn>
                    </v-col>
                  </v-row>
                </div>
              </v-col>
              <v-col cols="12" sm="7" md="7">
                <div class="infoprod mt-2 m-2">
                  <v-row justify="center" class="mt-2">
                    <v-card-text class="text-lg-body-1 text-xl-h6 text-center font-weight-bold py-2">INFORMAÇÕES DA PRODUÇÃO:</v-card-text>
                  </v-row>
                  <v-row class="pr-0 pl-1 pt-0 pb-1">
                    <v-col cols="6" class="py-3 py-lg-2">
                      <div style="display: flex; align-items: center">
                        <v-text-field
                          :density="inputText.size" :variant="inputTextVariant.variant"
                          hide-details="false" 
                          v-model="
                            appApontamento.resourceApontamento.nome_operador
                          "
                          label="OPERADOR"
                          readonly
                          style="font-weight: bold"
                          id="input_user_shop"
                          placeholder="Não Informado"
                          persistent-placeholder
                          clearable
                          @click:clear="ClearFuncionario"
                          @click="ShowFuncionarios"
                        ></v-text-field>
                        <v-btn
                          :size="squareButtons.size"
                          @click="ShowFuncionarios"
                          class=" ml-2"
                          color="secondary"
                        > 
                         <v-icon size="x-large" icon="mdi-magnify"></v-icon>
                        </v-btn>
                      </div>
                    </v-col>

                    <v-col cols="6" class="py-3 py-lg-2">
                      <div style="display: flex; align-items: center">
                        <v-text-field
                          :density="inputText.size" :variant="inputTextVariant.variant"
                          hide-details="false" 
                          v-model="
                            appApontamento.resourceApontamento.observacao
                          "
                          style="font-weight: bold;"
                          label="OBSERVAÇÃO"
                          id="input_user_shop"
                          placeholder="Não Informado "
                          persistent-placeholder
                          clearable
                          @click:clear="ClearObs"
                          @click="ShowObs"
                          readonly
                        ></v-text-field>
                        <v-btn
                          :size="squareButtons.size"
                          class=" ml-2"
                          @click="ShowObs"
                          color="secondary"
                        >  
                          <v-icon size="x-large" icon="mdi-file-edit-outline"></v-icon>
                        </v-btn>
                      </div>
                    </v-col>
                    <v-col
                      cols="12" sm="12" md="6" lg="6" xl="6"
                      class="py-3 py-lg-2"
                      v-if="
                        (appApontamento.resourceApontamento.tipo == 'Parada' &&
                          !appApontamento.isSetupClicked) ||
                        appApontamento.mostrarMotivoParada
                      "
                    >
                      <div style="display: flex; align-items: center">
                        <v-text-field
                          :density="inputText.size" :variant="inputTextVariant.variant"
                          hide-details="false"
                          :label="
                            appApontamento.resourceApontamento.tipo ==
                              'Parada' &&
                            appApontamento.resourceApontamento
                              .desc_motivo_parada == null
                              ? 'INFORMAR O MOTIVO DE PARADA'
                              : 'MOTIVO PARADA'
                          "
                          readonly
                          v-model="
                            appApontamento.resourceApontamento
                              .desc_motivo_parada
                          "
                          style="font-weight: bold;"
                          placeholder="Não Informado"
                          persistent-placeholder
                          clearable
                          @click="ShowMp"
                          @click:clear="ClearMotivoParada"
                        ></v-text-field>
                        <v-btn
                          v-if="
                            appApontamento.resourceApontamento
                              .motivo_de_parada_manutencao_setup !== 'Setup'
                          "
                          :size="squareButtons.size"
                          class=" ml-2"
                          @click="ShowMp"
                          color="secondary"
                        > 
                          <v-icon size="x-large" icon="mdi-magnify"></v-icon>
                        </v-btn>
                      </div>
                    </v-col>

                    <v-col cols="12" sm="12" 
                          :md="appApontamento.resourceApontamento.tipo != 'Parada'  ? '12' : '6'"  
                        class="py-3 py-lg-2">
                      <div style="display: flex; align-items: center">
                        <v-text-field
                          :density="inputText.size" :variant="inputTextVariant.variant"
                          hide-details="false" 
                          v-model="
                            appApontamento.resourceApontamento.ordem_de_producao
                          "
                          label="ORDEM DE PRODUÇÃO"
                          readonly
                          style="font-weight: bold;"
                          id="input_user_shop"
                          placeholder="Não Informado"
                          persistent-placeholder
                          clearable
                          @click="ShowOP"
                          @click:clear="ClearOP"
                        ></v-text-field>
                        <v-btn
                          :size="squareButtons.size"
                          class=" ml-2"
                          @click="ShowOP"
                          color="secondary"
                        > 
                          <v-icon size="x-large" icon="mdi-magnify"></v-icon>
                        </v-btn>
                      </div>
                    </v-col>
                  </v-row>
                </div>
              </v-col>
            </v-row>
          </v-card>
        </v-col>
      </v-row>
    </v-card>
  </v-dialog>
</template>

<script>
import eventosApontamento from "../../components/general/eventosApontamento.vue";
import { useAppStore } from "../../../stores/app";
import { useAppApontamento } from "../../../stores/appApontamento";
import { useAppFuncionarios } from "../../../stores/appFuncionarios";
import { useAppOrdemProducao } from "../../../stores/appOrdemProducao";
import { useAppMotivoDeParada } from "../../../stores/appMotivosdeParada";
import { useResponsiveSizes } from "../../utils/style";
import { useDisplay } from 'vuetify';
import {apontamentos_filtrados} from "../../utils/funcoes"

export default {
  name: "apontamento",
  data() {
    return {
      appApontamento: {
        resourceApontamento: { tipo: "", desc_motivo_parada: null },
        mostrarMotivoParada: true,
      },
      rectangleButtons: useResponsiveSizes().rectangleButtons,  
      display: useDisplay().name,
      inputText : useResponsiveSizes().inputText,
      inputTextVariant: useResponsiveSizes().inputTextVariant,
      squareButtons: useResponsiveSizes().squareButtons
    };
  },
  components: { eventosApontamento },

  computed: {
    appApontamento() {
      return useAppApontamento();
    },

    colorStatusApontamento() {
      switch (useAppApontamento().resourceApontamento.tipo) {
        case "Produção":
          return "#43A047";
        case "Parada":
          return "#D50000";
        case "Setup":
          return "#F9A825";
        case "Manutenção":
          return "#1E88E5";
        default:
          return "secondary";
      }
    },
  },

  methods: {
    async onVoltarApontamento() {        
      await useAppStore().filtrar_apontamentos(
        false,
        1,
        "onVoltarApontamento"
      ).then(async(res)=>{
          if (useAppStore().filtro_apontamentos_faltando_informacoes) {
            this.onFilterApontamentosSemInformacoes();
          }
        });
        
        useAppApontamento().showModal = false;
          useAppStore().view_apontamento_historico = false
    },
    async onFilterApontamentosSemInformacoes() {
        const apontamentos_atual = useAppStore().apontamentos_historico;

        if (apontamentos_atual.length == 0) {
        return;
        }

        useAppStore().apontamentos_historico = [];
        
        apontamentos_filtrados(apontamentos_atual)
        .then((apontamentos_filtrados) => {
            useAppStore().apontamentos_historico = apontamentos_filtrados;
            console.log("apontamentos_filtrados", apontamentos_filtrados);
           
        })
    },
    async click_manutencao() {
      useAppStore().load_actions = {
        message: "ABRINDO APONTAMENTO DE MANUTENÇÃO",
        showMessage: true,
      };

      useAppApontamento().resourceApontamento.tipo = "Manutenção";

      useAppApontamento().resourceApontamento.motivo_de_parada_manutencao_setup =
        "Manutenção";

      const json_update = {
        tipo: useAppApontamento().resourceApontamento.tipo,
        motivo_de_parada: null,
        desc_motivo_parada: null,
      };

      await useAppApontamento().update_apontamento(
        useAppApontamento().resourceApontamento.name,
        json_update
      );

      useAppStore().load_actions.showMessage = false;
    },

    async click_setup() {
      useAppStore().load_actions = {
        message: "ABRINDO APONTAMENTO DE SETUP",
        showMessage: true,
      };

      this.appApontamento.resourceApontamento.tipo = "Setup";

      useAppApontamento().resourceApontamento.tipo = "Setup";

      useAppApontamento().resourceApontamento.motivo_de_parada_manutencao_setup =
        "Setup";

  
      const json_update = {
        tipo: useAppApontamento().resourceApontamento.tipo,
        motivo_de_parada: null,
        desc_motivo_parada: null,
      };

      await useAppApontamento().update_apontamento(
        useAppApontamento().resourceApontamento.name,
        json_update
      );

      useAppStore().load_actions.showMessage = false;
    },

    ShowFuncionarios() {
      useAppStore().view_apontamento_historico = true;
      useAppFuncionarios().listar_funcionarios()
      useAppFuncionarios().showModal = true;
    },

    ShowOP() {
      useAppStore().view_apontamento_historico = true;
      useAppOrdemProducao().showModal = true;
    },

    ShowObs() {
      useAppStore().view_apontamento_historico = true;
      useAppApontamento().showModalObs = true;
      useAppApontamento().observacao_modal =
        useAppApontamento().resourceApontamento.observacao;
    },

    async ShowMp() {
      useAppStore().view_apontamento_historico = true;
      useAppMotivoDeParada().apontamento_name =
        useAppApontamento().resourceApontamento.name;
      await useAppMotivoDeParada().listar_motivos_de_parada();
      useAppStore().showModalMotivoDeParada = true;
    },

    async click_produzindo() {
      useAppStore().load_actions = {
        message: "ABRINDO APONTAMENTO",
        showMessage: true,
      };
      const status = "Produção";

      console.log("name_apontamento",useAppApontamento().resourceApontamento.name)
      
      await useAppApontamento().update_apontamento(
        useAppApontamento().resourceApontamento.name,
        { tipo: status }
      );
      await this.set_apontamento_tipo(status);
      useAppStore().load_actions.showMessage = false;
    },

    async motivo_de_parada() {
      useAppStore().load_actions = {
        message: "ABRINDO APONTAMENTO DE MOTIVO DE PARADA",
        showMessage: true,
      };
      const status = "Parada";

      useAppApontamento().resourceApontamento.tipo = status;
      useAppApontamento().resourceApontamento.motivo_de_parada_manutencao_setup =
        status;
      useAppApontamento().resourceApontamento.desc_motivo_parada = null;

      await useAppApontamento().update_apontamento(
        useAppApontamento().resourceApontamento.name,
        {
          tipo: status,
          motivo_de_parada: null,
          desc_motivo_parada: null,
          setup: 0,
          manutencao: 0,
        }
      );

      await this.set_apontamento_tipo(status);

      useAppStore().load_actions.showMessage = false;
    },

    async set_apontamento_tipo(status) {
      useAppApontamento().resourceApontamento.tipo = status;
    },

    async ClearFuncionario() {
      useAppStore().load_actions = {
        message: "SALVANDO APONTAMENTO",
        showMessage: true,
      };
      await useAppApontamento().update_apontamento(
        useAppApontamento().resourceApontamento.name,
        { operador: null }
      );
      useAppApontamento().resourceApontamento.nome_operador = null;
      useAppStore().load_actions.showMessage = false;
    },

    async ClearObs() {
      useAppStore().load_actions = {
        message: "SALVANDO APONTAMENTO",
        showMessage: true,
      };
      await useAppApontamento().update_apontamento(
        useAppApontamento().resourceApontamento.name,
        { observacao: null }
      );
      useAppApontamento().resourceApontamento.observacao = null;
      useAppStore().load_actions.showMessage = false;
    },

    async ClearOP() {
      useAppStore().load_actions = {
        message: "SALVANDO APONTAMENTO",
        showMessage: true,
      };
      await useAppApontamento().update_apontamento(
        useAppApontamento().resourceApontamento.name,
        { ordem_de_producao: null }
      );
      useAppApontamento().resourceApontamento.ordem_de_producao = null;
      useAppStore().load_actions.showMessage = false;
    },

    async ClearMotivoParada() {
      useAppStore().load_actions = {
        message: "SALVANDO APONTAMENTO",
        showMessage: true,
      };
      await useAppApontamento().update_apontamento(
        useAppApontamento().resourceApontamento.name,
        { motivo_de_parada: null, planejada: 0 }
      );
      useAppApontamento().resourceApontamento.desc_motivo_parada = null;
      useAppStore().load_actions.showMessage = false;
    },
    confirmarObservacao() {
      // Atualize o valor final de observacao quando o modal é confirmado
      useAppApontamento().resourceApontamento.observacao =
        useAppApontamento().observacao_modal;
    },
  },
};
</script>

<style></style>