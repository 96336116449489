<template>
    <div style="background-color: #dce0e6; height: 100% !important;">
        <v-row class="ma-2 mx-0">
            <v-col cols="12" md="6" class="pa-1">
                <div>
                    <v-card-text class="text-xl-h5 text-md-subtitle-1 text-sm-h6 text-subtitle-2 font-weight-bold pa-0"> 
                        {{ display == 'sm' || display == 'lg' || display == 'xl' || display == 'xxl' ? 'Tempo de Disponibilidade das máquinas por Data e Turno' : 'Tempo de Disp. das máquinas por Data e Turno' }}
                    </v-card-text>
                    <div  :style="display == 'xxl' ? 'height: calc(31vh - 5px);' : display == 'xl' ? 'height: calc(31vh - 30px);' : display == 'lg' ? 'height: calc(31vh - 43px);' : 'height: 210px;'">
                        <ejs-grid ref='grid' id='grid' height="100%" :dataSource="dataGridMaq" :allowFiltering='true' :allowSorting='true'>
                            <e-columns>
                                <e-column headerText='Data' width='150' :template="'dia'"></e-column>
                                <e-column headerText='Turno' width='150' :template="'turno'"></e-column>
                                <e-column headerText='Horas Disponíveis' width='150' :template="'hrs_disp_grid_maq'"></e-column>
                                <e-column headerText='Horas Produtivas' width='150' :template="'hrs_prod_disp_grid_maq'"></e-column>
                                <e-column headerText='Índice Disp.' width='100' :template="'indice_disp_grid_maq'"></e-column>
                            </e-columns>
                            <template #dia="{ data }">
                                <span class="text-caption font-weight-bold text-lg-subtitle-2 text-xl-h6 text-xxl-h5"> {{data.data}} </span>
                            </template>
                            <template #turno="{ data }">
                                <span class="text-caption font-weight-bold text-lg-subtitle-2 text-xl-h6 text-xxl-h5"> {{data.turno}} </span>
                            </template>
                            <template #hrs_disp_grid_maq="{ data }">
                                <span class="text-caption font-weight-bold text-lg-subtitle-2 text-xl-h6 text-xxl-h5"> {{data.hrs_disp}} </span>
                            </template>
                            <template #hrs_prod_disp_grid_maq="{ data }">
                                <span class="text-caption font-weight-bold text-lg-subtitle-2 text-xl-h6 text-xxl-h5"> {{data.hrs_prod}} </span>
                            </template>
                            <template #indice_disp_grid_maq="{ data }">
                                <span class="text-caption font-weight-bold text-lg-subtitle-2 text-xl-h6 text-xxl-h5"> {{data.indice_disp}} </span>
                            </template>
                        </ejs-grid>
                    </div>
                </div>
            </v-col>
            <v-col cols="12" md="3" class="pa-1 px-0">
                <div>
                    <v-card-text class="text-xl-h5 text-md-subtitle-1 text-sm-h6 text-subtitle-2 font-weight-bold pa-0">
                        {{ display == 'sm' || display == 'lg' || display == 'xl' || display == 'xxl' ? 'Detalhamento de Disponibilidade' : 'Detalhamento de Disp.' }}
                    </v-card-text>
                    <div :style="display == 'xxl' ? 'height: calc(31vh - 5px);' : display == 'xl' ? 'height: calc(31vh - 30px);' : display == 'lg' ? 'height: calc(31vh - 43px);' : 'height: 210px;'">
                        <ejs-grid ref='grid' id='grid' height="100%" :dataSource="dataGridDetalhe" :allowFiltering='true'
                            :allowSorting='true'>
                            <e-columns>
                                <e-column  headerText='Status' width='150' :template="'status'"></e-column>
                                <e-column  headerText='Duração' width='150' :template="'hr_total_detalhe'"></e-column>
                            </e-columns>
                            <template #status="{ data }">
                                <span class="text-caption font-weight-bold text-lg-subtitle-2 text-xl-h6 text-xxl-h5"> {{data.nameApont}} </span>
                            </template>
                            <template #hr_total_detalhe="{ data }">
                                <span class="text-caption font-weight-bold text-lg-subtitle-2 text-xl-h6 text-xxl-h5"> {{data.total_hr_formated}} </span>
                            </template>
                        </ejs-grid>
                    </div>
                </div>
            </v-col>
            <v-col cols="12" md="3" class="pa-1">
                <div>
                    <v-card-text class="text-xl-h5 text-md-subtitle-1 text-sm-h6 text-subtitle-2 font-weight-bold pa-0">Disponibilidade Geral</v-card-text>
                    <div :style="display == 'xxl' ? 'height: calc(31vh - 5px);' : display == 'xl' ? 'height: calc(31vh - 30px);' : display == 'lg' ? 'height: calc(31vh - 43px);' : 'height: 210px;'">
                        <ejs-grid ref='grid' id='grid' height="100%" :dataSource="dataGridGeral" :allowFiltering='true'
                            :allowSorting='true'>
                            <e-columns>
                                <e-column headerText='Hrs Disp.' width='150' :template="'hr_totais_geral'"></e-column>
                                <e-column headerText='Hrs Prod.' width='150' :template="'hrs_prod_gerais'"></e-column>
                                <e-column headerText='Índice de Disp.' width='150' :template="'indice_disp_geral'"></e-column>
                            </e-columns>
                            <template #hr_totais_geral="{ data }">
                                <span class="text-caption font-weight-bold text-lg-subtitle-2 text-xl-h6 text-xxl-h5"> {{data.hrs_totais}} </span>
                            </template>
                            <template #hrs_prod_gerais="{ data }"> 
                                <span class="text-caption font-weight-bold text-lg-subtitle-2 text-xl-h6 text-xxl-h5"> {{data.hrs_totais_prod}} </span>
                            </template>
                            <template #indice_disp_geral="{ data }">
                                <span class="text-caption font-weight-bold text-lg-subtitle-2 text-xl-h6 text-xxl-h5"> {{data.indice_disp}} </span>
                            </template>
                        </ejs-grid>
                    </div>
                </div>
            </v-col>
        </v-row>
    </div>
</template>

<script>
import { useAppCharts } from "../../../stores/appCharts";
import { GridComponent, ColumnsDirective, ColumnDirective } from '@syncfusion/ej2-vue-grids'; 
import { useDisplay } from 'vuetify'

export default {
    name: "tabela_disponibilidade_resumida",
    data() {
        return{
            display: useDisplay().name,
        }
    },
    components: {
        'ejs-grid': GridComponent,
        'e-columns': ColumnsDirective,
        'e-column': ColumnDirective,
    },
    props: {
        gridDetalhe: Object,
        gridGeral: Object,
        gridMaq: Object
    },
    computed: {
        appCharts() {
            return useAppCharts();
        },
        dataGridDetalhe() {
            return this.gridDetalhe
        },
        dataGridGeral(){
            return this.gridGeral
        },
        dataGridMaq() {
            return this.gridMaq
        }

    }
}
</script>
