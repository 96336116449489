import { createApp } from "vue";
import { createVuetify } from "vuetify";
import { createPinia } from "pinia";
import { registerLicense } from "@syncfusion/ej2-base";
import piniaPersist from "pinia-plugin-persist";
import * as components from "vuetify/components";
import * as directives from "vuetify/directives";

import routerIOT from '../vue/apontamento-iot/routes/index'
import IOTApp from "../vue/apontamento-iot/app.vue";



registerLicense("Ngo9BigBOggjHTQxAR8/V1NDaF5cWWtCf1FpRmJGdld5fUVHYVZUTXxaS00DNHVRdkdmWX1edHVXRmNfUENwW0Q=");
const Lighttheme = {
  dark: false,
  variables: {},
  colors: {
    primary: "#121935",
    secondary: "#397BBE",
    cards: "#EEEEEE",
    bcinputs: "#FFFFFF",
  },
};

vuetify = createVuetify({
  components,
  directives,
  theme: {
    themes: {
      light: Lighttheme,
    },
  },
});

class ApontamentoWeb {
  constructor({ wrapper }) {
    try {
      this.$wrapper = $(wrapper);
      const app = createApp(WebApp);
      const Lighttheme = {
        dark: false,
        variables: {},
        colors: {
          primary: "#121935",
          secondary: "#397BBE",
          cards: "#EEEEEE",
          bcinputs: "#FFFFFF",
        },
      };
      vuetify = createVuetify({
        components,
        directives,
        theme: {
          themes: {
            light: Lighttheme,
          },
        },
      });

      const pinia = createPinia();

      pinia.use(piniaPersist);

      SetVueGlobals(app);
      app.use(vuetify).use(pinia).mount(this.$wrapper.get(0));
    } catch (e) {
      console.error(e);
    }
  }
}


class OperadorXRecurso {
  constructor({ wrapper }) {
    try {
      this.$wrapper = $(wrapper);
      const app = createApp(OPxRecApp);
      const Lighttheme = {
        dark: false,
        variables: {},
        colors: {
          primary: "#121935",
          secondary: "#397BBE",
          cards: "#EEEEEE",
          bcinputs: "#FFFFFF",
        },
      };
      vuetify = createVuetify({
        components,
        directives,
        theme: {
          themes: {
            light: Lighttheme,
          },
        },
      });
      const pinia = createPinia();
      pinia.use(piniaPersist);
      SetVueGlobals(app);
      app.use(vuetify).use(pinia).mount(this.$wrapper.get(0));
    } catch (e) {
      console.error(e);
    }
  }
}

class ApontamentoIOT {
  constructor({ wrapper }) {
    try {
      registerLicense("Ngo9BigBOggjHTQxAR8/V1NDaF5cWWtCf1FpRmJGdld5fUVHYVZUTXxaS00DNHVRdkdmWX1ed3RcRWZdVUF0XUc=");
      this.$wrapper = $(wrapper);

      const app = createApp(IOTApp);
      
      const pinia = createPinia();

      pinia.use(piniaPersist);

      SetVueGlobals(app);

      app.use(vuetify).use(pinia).use(routerIOT).mount(this.$wrapper.get(0));
    
    } catch (e) {
      console.error(e);
    }
  }
}

frappe.provide("frappe.apontamento");

frappe.apontamento.ApontamentoIOT = ApontamentoIOT;