import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { style: {"background-color":"#dce0e6","height":"100% !important"} }
const _hoisted_2 = { class: "text-caption font-weight-bold text-lg-subtitle-2 text-xl-h6 text-xxl-h5" }
const _hoisted_3 = { class: "text-caption font-weight-bold text-lg-subtitle-2 text-xl-h6 text-xxl-h5" }
const _hoisted_4 = { class: "text-caption font-weight-bold text-lg-subtitle-2 text-xl-h6 text-xxl-h5" }
const _hoisted_5 = { class: "text-caption font-weight-bold text-lg-subtitle-2 text-xl-h6 text-xxl-h5" }
const _hoisted_6 = { class: "text-caption font-weight-bold text-lg-subtitle-2 text-xl-h6 text-xxl-h5" }
const _hoisted_7 = { class: "text-caption font-weight-bold text-lg-subtitle-2 text-xl-h6 text-xxl-h5" }
const _hoisted_8 = { class: "text-caption font-weight-bold text-lg-subtitle-2 text-xl-h6 text-xxl-h5" }
const _hoisted_9 = { class: "text-caption font-weight-bold text-lg-subtitle-2 text-xl-h6 text-xxl-h5" }
const _hoisted_10 = { class: "text-caption font-weight-bold text-lg-subtitle-2 text-xl-h6 text-xxl-h5" }
const _hoisted_11 = { class: "text-caption font-weight-bold text-lg-subtitle-2 text-xl-h6 text-xxl-h5" }

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_v_card_text = _resolveComponent("v-card-text")
  const _component_e_column = _resolveComponent("e-column")
  const _component_e_columns = _resolveComponent("e-columns")
  const _component_ejs_grid = _resolveComponent("ejs-grid")
  const _component_v_col = _resolveComponent("v-col")
  const _component_v_row = _resolveComponent("v-row")

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_v_row, { class: "ma-2 mx-0" }, {
      default: _withCtx(() => [
        _createVNode(_component_v_col, {
          cols: "12",
          md: "6",
          class: "pa-1"
        }, {
          default: _withCtx(() => [
            _createElementVNode("div", null, [
              _createVNode(_component_v_card_text, { class: "text-xl-h5 text-md-subtitle-1 text-sm-h6 text-subtitle-2 font-weight-bold pa-0" }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString($data.display == 'sm' || $data.display == 'lg' || $data.display == 'xl' || $data.display == 'xxl' ? 'Tempo de Disponibilidade das máquinas por Data e Turno' : 'Tempo de Disp. das máquinas por Data e Turno'), 1 /* TEXT */)
                ]),
                _: 1 /* STABLE */
              }),
              _createElementVNode("div", {
                style: _normalizeStyle($data.display == 'xxl' ? 'height: calc(31vh - 5px);' : $data.display == 'xl' ? 'height: calc(31vh - 30px);' : $data.display == 'lg' ? 'height: calc(31vh - 43px);' : 'height: 210px;')
              }, [
                _createVNode(_component_ejs_grid, {
                  ref: "grid",
                  id: "grid",
                  height: "100%",
                  dataSource: $options.dataGridMaq,
                  allowFiltering: true,
                  allowSorting: true
                }, {
                  dia: _withCtx(({ data }) => [
                    _createElementVNode("span", _hoisted_2, _toDisplayString(data.data), 1 /* TEXT */)
                  ]),
                  turno: _withCtx(({ data }) => [
                    _createElementVNode("span", _hoisted_3, _toDisplayString(data.turno), 1 /* TEXT */)
                  ]),
                  hrs_disp_grid_maq: _withCtx(({ data }) => [
                    _createElementVNode("span", _hoisted_4, _toDisplayString(data.hrs_disp), 1 /* TEXT */)
                  ]),
                  hrs_prod_disp_grid_maq: _withCtx(({ data }) => [
                    _createElementVNode("span", _hoisted_5, _toDisplayString(data.hrs_prod), 1 /* TEXT */)
                  ]),
                  indice_disp_grid_maq: _withCtx(({ data }) => [
                    _createElementVNode("span", _hoisted_6, _toDisplayString(data.indice_disp), 1 /* TEXT */)
                  ]),
                  default: _withCtx(() => [
                    _createVNode(_component_e_columns, null, {
                      default: _withCtx(() => [
                        _createVNode(_component_e_column, {
                          headerText: "Data",
                          width: "150",
                          template: 'dia'
                        }),
                        _createVNode(_component_e_column, {
                          headerText: "Turno",
                          width: "150",
                          template: 'turno'
                        }),
                        _createVNode(_component_e_column, {
                          headerText: "Horas Disponíveis",
                          width: "150",
                          template: 'hrs_disp_grid_maq'
                        }),
                        _createVNode(_component_e_column, {
                          headerText: "Horas Produtivas",
                          width: "150",
                          template: 'hrs_prod_disp_grid_maq'
                        }),
                        _createVNode(_component_e_column, {
                          headerText: "Índice Disp.",
                          width: "100",
                          template: 'indice_disp_grid_maq'
                        })
                      ]),
                      _: 1 /* STABLE */
                    })
                  ]),
                  _: 1 /* STABLE */
                }, 8 /* PROPS */, ["dataSource"])
              ], 4 /* STYLE */)
            ])
          ]),
          _: 1 /* STABLE */
        }),
        _createVNode(_component_v_col, {
          cols: "12",
          md: "3",
          class: "pa-1 px-0"
        }, {
          default: _withCtx(() => [
            _createElementVNode("div", null, [
              _createVNode(_component_v_card_text, { class: "text-xl-h5 text-md-subtitle-1 text-sm-h6 text-subtitle-2 font-weight-bold pa-0" }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString($data.display == 'sm' || $data.display == 'lg' || $data.display == 'xl' || $data.display == 'xxl' ? 'Detalhamento de Disponibilidade' : 'Detalhamento de Disp.'), 1 /* TEXT */)
                ]),
                _: 1 /* STABLE */
              }),
              _createElementVNode("div", {
                style: _normalizeStyle($data.display == 'xxl' ? 'height: calc(31vh - 5px);' : $data.display == 'xl' ? 'height: calc(31vh - 30px);' : $data.display == 'lg' ? 'height: calc(31vh - 43px);' : 'height: 210px;')
              }, [
                _createVNode(_component_ejs_grid, {
                  ref: "grid",
                  id: "grid",
                  height: "100%",
                  dataSource: $options.dataGridDetalhe,
                  allowFiltering: true,
                  allowSorting: true
                }, {
                  status: _withCtx(({ data }) => [
                    _createElementVNode("span", _hoisted_7, _toDisplayString(data.nameApont), 1 /* TEXT */)
                  ]),
                  hr_total_detalhe: _withCtx(({ data }) => [
                    _createElementVNode("span", _hoisted_8, _toDisplayString(data.total_hr_formated), 1 /* TEXT */)
                  ]),
                  default: _withCtx(() => [
                    _createVNode(_component_e_columns, null, {
                      default: _withCtx(() => [
                        _createVNode(_component_e_column, {
                          headerText: "Status",
                          width: "150",
                          template: 'status'
                        }),
                        _createVNode(_component_e_column, {
                          headerText: "Duração",
                          width: "150",
                          template: 'hr_total_detalhe'
                        })
                      ]),
                      _: 1 /* STABLE */
                    })
                  ]),
                  _: 1 /* STABLE */
                }, 8 /* PROPS */, ["dataSource"])
              ], 4 /* STYLE */)
            ])
          ]),
          _: 1 /* STABLE */
        }),
        _createVNode(_component_v_col, {
          cols: "12",
          md: "3",
          class: "pa-1"
        }, {
          default: _withCtx(() => [
            _createElementVNode("div", null, [
              _createVNode(_component_v_card_text, { class: "text-xl-h5 text-md-subtitle-1 text-sm-h6 text-subtitle-2 font-weight-bold pa-0" }, {
                default: _withCtx(() => [
                  _createTextVNode("Disponibilidade Geral")
                ]),
                _: 1 /* STABLE */
              }),
              _createElementVNode("div", {
                style: _normalizeStyle($data.display == 'xxl' ? 'height: calc(31vh - 5px);' : $data.display == 'xl' ? 'height: calc(31vh - 30px);' : $data.display == 'lg' ? 'height: calc(31vh - 43px);' : 'height: 210px;')
              }, [
                _createVNode(_component_ejs_grid, {
                  ref: "grid",
                  id: "grid",
                  height: "100%",
                  dataSource: $options.dataGridGeral,
                  allowFiltering: true,
                  allowSorting: true
                }, {
                  hr_totais_geral: _withCtx(({ data }) => [
                    _createElementVNode("span", _hoisted_9, _toDisplayString(data.hrs_totais), 1 /* TEXT */)
                  ]),
                  hrs_prod_gerais: _withCtx(({ data }) => [
                    _createElementVNode("span", _hoisted_10, _toDisplayString(data.hrs_totais_prod), 1 /* TEXT */)
                  ]),
                  indice_disp_geral: _withCtx(({ data }) => [
                    _createElementVNode("span", _hoisted_11, _toDisplayString(data.indice_disp), 1 /* TEXT */)
                  ]),
                  default: _withCtx(() => [
                    _createVNode(_component_e_columns, null, {
                      default: _withCtx(() => [
                        _createVNode(_component_e_column, {
                          headerText: "Hrs Disp.",
                          width: "150",
                          template: 'hr_totais_geral'
                        }),
                        _createVNode(_component_e_column, {
                          headerText: "Hrs Prod.",
                          width: "150",
                          template: 'hrs_prod_gerais'
                        }),
                        _createVNode(_component_e_column, {
                          headerText: "Índice de Disp.",
                          width: "150",
                          template: 'indice_disp_geral'
                        })
                      ]),
                      _: 1 /* STABLE */
                    })
                  ]),
                  _: 1 /* STABLE */
                }, 8 /* PROPS */, ["dataSource"])
              ], 4 /* STYLE */)
            ])
          ]),
          _: 1 /* STABLE */
        })
      ]),
      _: 1 /* STABLE */
    })
  ]))
}