import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { style: {"height":"95%"} }

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_e_series = _resolveComponent("e-series")
  const _component_e_series_collection = _resolveComponent("e-series-collection")
  const _component_ejs_chart = _resolveComponent("ejs-chart")

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_ejs_chart, {
      theme: _ctx.theme,
      align: "center",
      id: "chartcontainer",
      title: _ctx.title,
      legendSettings: _ctx.legendSettings,
      primaryXAxis: _ctx.primaryXAxis,
      primaryYAxis: _ctx.primaryYAxis,
      chartArea: _ctx.chartArea,
      width: _ctx.width,
      tooltip: _ctx.tooltip,
      sharedTooltipRender: $options.sharedTooltipRender
    }, {
      default: _withCtx(() => [
        _createVNode(_component_e_series_collection, null, {
          default: _withCtx(() => [
            _createVNode(_component_e_series, {
              dataSource: $options.chartData,
              type: "Pareto",
              xName: "motivo_de_parada",
              yName: "total_horas",
              name: "QUANTIDADE DE HORAS",
              width: "2",
              columnWidth: "0.4",
              paretoOptions: _ctx.paretoOptions,
              cornerRadius: _ctx.cornerRadius,
              fill: '#121935'
            }, null, 8 /* PROPS */, ["dataSource", "paretoOptions", "cornerRadius"])
          ]),
          _: 1 /* STABLE */
        })
      ]),
      _: 1 /* STABLE */
    }, 8 /* PROPS */, ["theme", "title", "legendSettings", "primaryXAxis", "primaryYAxis", "chartArea", "width", "tooltip", "sharedTooltipRender"])
  ]))
}