<template>
<v-snackbar v-model="showSnack" :color="color" vertical>
    <div class="text-subtitle-1 pb-2" style="font-weight: bold;color:white">{{ title }}</div>

    <p style="font-size: 16px;color: white;font-weight: bold;">{{ message }}</p>

    <template v-slot:actions v-if="showButton">
        <v-btn color="white" style="font-weight: bold;color:white" variant="text" @click="onClose()">
            {{ messageButton }}
        </v-btn>
    </template>
</v-snackbar>
</template>

<script>
export default {
    name: 'modalSnack',
    emits: ['onClose'],
    props: {
        showButton: {
            type: Boolean,
            default: false
        },
        showSnackProp: {
            type: Boolean,
            default: false
        },
        title: {
            type: String,
            default: ''
        },
        message: {
            type: String,
            default: ''
        },
        messageButton: {
            type: String,
            default: ''
        },
        timeOutSec: {
            type: Number,
            default: 0
        },
        color: {
            type: String,
            default: 'success'
        },
    },
    
    data() {
        return {
            timeout: null,
            showSnack: this.showSnackProp
        }
        
    },
    methods: {
        onClose() {
            clearTimeout(this.timeout);
            this.$emit('onClose', false);
        },
    },
    watch: {
        showSnackProp(newVal) {
            this.showSnack = newVal;
            if (this.timeOutSec > 0) {
                console.log('entrou no if')
                if (newVal) {
                    console.log(this.timeOutSec)
                    this.timeout = setTimeout(() => {
                        this.onClose()
                    }, this.timeOutSec * 1000);
                }
            }
            
        }
    }
}
</script>
