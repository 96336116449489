import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createElementBlock as _createElementBlock, withCtx as _withCtx, createVNode as _createVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-1838355e"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  class: "pa-1",
  style: {"width":"100%"}
}
const _hoisted_2 = {
  style: {"width":"68%"},
  class: "duas-linhas"
}
const _hoisted_3 = {
  key: 0,
  class: "ml-1"
}
const _hoisted_4 = {
  key: 0,
  class: "duas-linhas",
  style: {"background-color":"#3BA140","width":"100%"}
}
const _hoisted_5 = {
  key: 1,
  class: "duas-linhas",
  style: {"background-color":"#FFFB02","color":"black","width":"100%"}
}
const _hoisted_6 = {
  key: 0,
  color: "#388E3C",
  width: "100%",
  class: "duas-linhas",
  style: {"background-color":"#FF4F42","width":"100%"}
}
const _hoisted_7 = {
  key: 1,
  class: "duas-linhas",
  style: {"background-color":"#FFFB02","color":"black","width":"100%"}
}
const _hoisted_8 = {
  key: 0,
  class: "duas-linhas",
  style: {"background-color":"#FFCA65","width":"100%"}
}
const _hoisted_9 = {
  key: 1,
  class: "duas-linhas",
  style: {"background-color":"#FFFB02","color":"black","width":"100%"}
}
const _hoisted_10 = {
  key: 0,
  class: "duas-linhas",
  style: {"background-color":"#5A9BEF","width":"100%"}
}

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_v_icon = _resolveComponent("v-icon")
  const _component_v_card = _resolveComponent("v-card")

  return (_openBlock(), _createBlock(_component_v_card, {
    class: "d-flex ma-2",
    color: $props.color,
    height: $props.tipo == null ? 90 : 180
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", {
        style: _normalizeStyle([$options.colorStatusSensor, {"height":"100%","width":"40px"}]),
        class: "d-flex justify-center align-center"
      }, [
        ($props.state_sensor == 'OFFLINE')
          ? (_openBlock(), _createBlock(_component_v_icon, {
              key: 0,
              icon: "mdi-lan-disconnect",
              color: "white",
              size: "31"
            }))
          : (_openBlock(), _createBlock(_component_v_icon, {
              key: 1,
              icon: "mdi-lan-connect",
              size: "31"
            }))
      ], 4 /* STYLE */),
      _createElementVNode("div", _hoisted_1, [
        _createVNode(_component_v_card, {
          flat: "",
          color: "transparent",
          class: "d-flex flex-wrap flex-md-nowrap align-items-center justify-space-between",
          height: $props.tipo == null ? '100%': $props.tipo == 'Manutenção' && $props.observacao == '' ? '100%' : '50%',
          style: {"width":"100%"}
        }, {
          default: _withCtx(() => [
            _createElementVNode("span", _hoisted_2, _toDisplayString($props.name), 1 /* TEXT */),
            ($props.status != 'offline')
              ? (_openBlock(), _createElementBlock("span", _hoisted_3, _toDisplayString(_ctx.tempExecucao), 1 /* TEXT */))
              : _createCommentVNode("v-if", true)
          ]),
          _: 1 /* STABLE */
        }, 8 /* PROPS */, ["height"]),
        ($props.tipo == 'Produção')
          ? (_openBlock(), _createBlock(_component_v_card, {
              key: 0,
              color: "transparent",
              flat: "",
              class: "d-flex align-items-center",
              width: "100%",
              height: "49%"
            }, {
              default: _withCtx(() => [
                ($props.op)
                  ? (_openBlock(), _createElementBlock("span", _hoisted_4, _toDisplayString($props.op), 1 /* TEXT */))
                  : (_openBlock(), _createElementBlock("span", _hoisted_5, "O.P. NÃO INFORMADA"))
              ]),
              _: 1 /* STABLE */
            }))
          : _createCommentVNode("v-if", true),
        ($props.tipo == 'Parada')
          ? (_openBlock(), _createBlock(_component_v_card, {
              key: 1,
              flat: "",
              color: "transparent",
              class: "d-flex align-items-center",
              width: "100%",
              height: "49%"
            }, {
              default: _withCtx(() => [
                ($props.motivo_de_parada)
                  ? (_openBlock(), _createElementBlock("span", _hoisted_6, _toDisplayString($props.motivo_de_parada), 1 /* TEXT */))
                  : (_openBlock(), _createElementBlock("span", _hoisted_7, "M.P. NÃO INFORMADO"))
              ]),
              _: 1 /* STABLE */
            }))
          : _createCommentVNode("v-if", true),
        ($props.tipo == 'Setup')
          ? (_openBlock(), _createBlock(_component_v_card, {
              key: 2,
              flat: "",
              color: "transparent",
              class: "d-flex align-items-center",
              width: "100%",
              height: "49%"
            }, {
              default: _withCtx(() => [
                ($props.op)
                  ? (_openBlock(), _createElementBlock("span", _hoisted_8, _toDisplayString($props.op), 1 /* TEXT */))
                  : (_openBlock(), _createElementBlock("span", _hoisted_9, "O.P. NÃO INFORMADA"))
              ]),
              _: 1 /* STABLE */
            }))
          : _createCommentVNode("v-if", true),
        ($props.tipo == 'Manutenção' && $props.observacao)
          ? (_openBlock(), _createBlock(_component_v_card, {
              key: 3,
              flat: "",
              color: "transparent",
              class: "d-flex align-items-center",
              width: "100%",
              height: "49%"
            }, {
              default: _withCtx(() => [
                ($props.observacao)
                  ? (_openBlock(), _createElementBlock("span", _hoisted_10, _toDisplayString($props.observacao), 1 /* TEXT */))
                  : _createCommentVNode("v-if", true)
              ]),
              _: 1 /* STABLE */
            }))
          : _createCommentVNode("v-if", true)
      ])
    ]),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["color", "height"]))
}