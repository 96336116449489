import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createSlots as _createSlots, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = {
  class: "text-subtitle-1 pb-2",
  style: {"font-weight":"bold","color":"white"}
}
const _hoisted_2 = { style: {"font-size":"16px","color":"white","font-weight":"bold"} }

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_v_btn = _resolveComponent("v-btn")
  const _component_v_snackbar = _resolveComponent("v-snackbar")

  return (_openBlock(), _createBlock(_component_v_snackbar, {
    modelValue: $data.showSnack,
    "onUpdate:modelValue": _cache[1] || (_cache[1] = $event => (($data.showSnack) = $event)),
    color: $props.color,
    vertical: ""
  }, _createSlots({
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, _toDisplayString($props.title), 1 /* TEXT */),
      _createElementVNode("p", _hoisted_2, _toDisplayString($props.message), 1 /* TEXT */)
    ]),
    _: 2 /* DYNAMIC */
  }, [
    ($props.showButton)
      ? {
          name: "actions",
          fn: _withCtx(() => [
            _createVNode(_component_v_btn, {
              color: "white",
              style: {"font-weight":"bold","color":"white"},
              variant: "text",
              onClick: _cache[0] || (_cache[0] = $event => ($options.onClose()))
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString($props.messageButton), 1 /* TEXT */)
              ]),
              _: 1 /* STABLE */
            })
          ]),
          key: "0"
        }
      : undefined
  ]), 1032 /* PROPS, DYNAMIC_SLOTS */, ["modelValue", "color"]))
}