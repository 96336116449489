<template>
     <div style="background-color: #dce0e6; height: 100%;">
        <div cols="12" class="text-center ">
            <v-card-text class="text-lg-subtitle-2 text-xl-h4 text-md-h5 text-h5 text-center font-weight-bold pb-0 pt-1 mb-n3">DISPONIBILIDADE RESUMIDA</v-card-text>
        </div>
        <div aligh="center" style="height: 95%" >
            <ejs-accumulationchart ref="pie" :theme="theme" style="width: 100%;"
                aligh="center" id="chartcontainer" :title="title" :legendSettings="legendSettings"
                :tooltip="tooltip" enableSmartLables="true" :enableAnimation="enableAnimation"
                :enableBorderOnMouseMove="false">
                <e-accumulation-series-collection>
                    <e-accumulation-series :dataSource="chartData" :startAngle="startAngle"
                        :explodeOffset="explodeOffset" :dataLabel="dataLabel" :explodeIndex="explodeIndex"
                        :radius="radius" xName="nameApont" yName="total_hr" name="Browser" innerRadius="0%"
                        explode="false">
                    </e-accumulation-series>
                </e-accumulation-series-collection>
            </ejs-accumulationchart>
        </div>
    </div>
</template>

<script>
import { Browser } from "@syncfusion/ej2-base";
import {
    AccumulationChartComponent,
    AccumulationSeriesCollectionDirective,
    AccumulationSeriesDirective,
    AccumulationTooltip,
    PieSeries,
    AccumulationDataLabel,
    AccumulationLegend,
} from "@syncfusion/ej2-vue-charts";
import { useAppCharts } from "../../../stores/appCharts";

import { useDisplay } from 'vuetify'

let selectedTheme = location.hash.split("/")[1];
selectedTheme = selectedTheme ? selectedTheme : "Fluent2";
let theme = (selectedTheme.charAt(0).toUpperCase() + selectedTheme.slice(1))
    .replace(/-dark/i, "Dark")
    .replace(/contrast/i, "Contrast")
    .replace(/-highContrast/i, "HighContrast");

export default {
    name: "pie_disponibilidade_resumida",
    components: {
        "ejs-accumulationchart": AccumulationChartComponent,
        "e-accumulation-series-collection": AccumulationSeriesCollectionDirective,
        "e-accumulation-series": AccumulationSeriesDirective,
    },
    props: {
        dataChart: Object
    },
    data() {
        return {
            display: useDisplay().name,
            count: 0,
            theme: theme,
            tooltip: {
                enable: true,
                format: "<b>${point.x}</b><br>Representa: <b>${point.y}%</b>",
                header: "",
            },

            dataLabel: {
                visible: false,
            },
            
            enableSmartLabels: false,
            enableAnimation: false,
            startAngle: Browser.isDevice ? "55" : "35",
            explodeOffset: "10%",
            explodeIndex: 0,
        };
    },
    
    provide: {
        accumulationchart: [
            AccumulationLegend,
            PieSeries,
            AccumulationTooltip,
            AccumulationDataLabel,
        ],
    },
    computed: {
        appCharts() {
            return useAppCharts();
        },
        chartData() {
            return this.dataChart
        },
        legendSettings() {
            return {
                visible: true,
                textWrap: 'Wrap',
                width: '30%',
                shapeHeight: this.legendIcon,
                shapeWidth: this.legendIcon,
                textStyle: {
                    size: this.legendFont,
                    fontWeight: '700',
                    justify: 'right',
                },
                toggleVisibility: false,
            }
        },
        legendFont() {
            switch (this.display) {
                case 'xs': return '8px'
                case 'sm': return '12px'
                case 'md': return '12px'
                case 'lg': return '12px'
                case 'xl': return '20px'
                case 'xxl': return '30px'
                }
        },
        legendIcon(){
            switch (this.display) {
            case 'xs': return 9;
            case 'sm': return 10;
            case 'md': return 12;
            case 'lg': return 12;
            case 'xl': return 20;
            case 'xxl': return 22;
            }
        }
    },
};
</script>

<style>
#chartcontainer {
    height: 100% !important;
}
</style>
