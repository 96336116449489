<template>
  <v-dialog
    v-model="appStore.showModalHistorico"
    transition="dialog-bottom-transition"
    fullscreen
    v-bind:is="appOp"
  >
    <v-toolbar color="primary"> </v-toolbar>
    <v-card>
      <v-container fluid class="mt-0">
        <v-row>
          <v-col cols="12" class="pb-0">
            <v-toolbar height="80" dark color="#37474F">
              <v-btn
                @click="onVoltar()"
                color="#EEEEEE"
                class="ml-3 mr-2 py-0 align-center"
                variant="flat"
                height="42"
              >
                <v-icon icon="mdi-arrow-left-circle" size="35"></v-icon>
                <v-card-text
                  v-if="display == 'lg' || display == 'xl' || display == 'xxl'"
                  class="py-0 font-weight-bold text-h5"
                  >VOLTAR</v-card-text
                >
              </v-btn>
              <v-toolbar-title class="font-weight-bold"
                >HISTÓRICOS ANTERIORES</v-toolbar-title
              >
              <div>
                <v-checkbox
                  style="color: white; font-weight: bold"
                  class="mt-5 pt-0 pb-0 mr-4"
                  :label="
                    display == 'lg' || display == 'xl' || display == 'xxl'
                      ? 'FALTANDO INFORMAÇÕES'
                      : 'FALT. INFOS.'
                  "
                  v-model="appStore.filtro_apontamentos_faltando_informacoes"
                ></v-checkbox>
              </div>
              <div>
                <ejs-daterangepicker
                  ref="dateRangePicker"
                  style="
                    height: 40px;
                    font-size: 16px;
                    font-weight: 600;
                    width: 100%;
                  "
                  :format="'dd/MM/yyyy'"
                  :value="defaultDateRange"
                  @keydown="applyDateRangeFormat"
                  @click="showCalendar"
                  @change="onDateChange"
                ></ejs-daterangepicker>
              </div>
              <template v-slot:append>
                <v-btn
                  variant="flat"
                  class="ml-3 mr-2 py-0 align-center"
                  color="secondary"
                  height="42"
                  @click="OnSearchHistoricos"
                >
                  <v-card-text
                    v-if="
                      display == 'lg' || display == 'xl' || display == 'xxl'
                    "
                    class="py-0 font-weight-bold text-h5"
                    >PESQUISAR</v-card-text
                  >
                  <v-icon icon="mdi-magnify" size="35"></v-icon>
                </v-btn>
              </template>
            </v-toolbar>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="12" class="pt-0">
            <v-card flat rounded="0">
              <v-data-table
                :headers="headers"
                :no-data-text="noDataText"
                :items="appStore.apontamentos_historico"
                :items-per-page-text="'Itens por página'"
                :items-per-page-all-text="'Todos'"
                items-per-page="25"
                item-value="name"
                :height="
                  display == 'lg' || display == 'xl' || display == 'xxl'
                    ? 'calc(100vh - 210px)'
                    : '60vh'
                "
                width="100%"
                id="histHoje"
              >
                <template v-slot:no-data>
                  <v-card-text class="text-h6 font-weight-bold">
                    <v-icon
                      color="black"
                      class="mb-1"
                      icon="mdi-emoticon-cool"
                    ></v-icon>
                    {{ noDataText }}
                  </v-card-text>
                </template>
                <template v-slot:item="{ item }">
                  <tr
                    :style="CustomizaLinha(item.tipo)"
                    class="font-weight-bold boder-sm border-opacity-100 border-bcinputs"
                    style="border-color: #fff !important"
                  >
                    <td style="width: 30px">
                      <div class="d-flex justify-center content-center">
                        <v-icon
                          @click="ShowApontamento(item)"
                          icon="mdi mdi-file-edit-outline"
                          size="35"
                          style="cursor: pointer"
                        ></v-icon>
                      </div>
                    </td>
                    <td style="color: #fff; width: 30px">
                      <v-card
                        v-if="verify_status_sensor(item.status_sensor)"
                        :color="status_color(item.status_sensor)"
                        class="d-flex justify-center"
                        style="
                          min-width: 70px;
                          height: 61px;
                          display: flex;
                          justify-content: center;
                          align-items: center;
                          border-radius: 0px;
                        "
                      >
                        <v-icon size="40">mdi-lan-connect</v-icon>
                      </v-card>
                      <v-card
                        v-else
                        :color="'#37474F'"
                        style="
                          min-width: 70px;
                          height: 61px;
                          display: flex;
                          justify-content: center;
                          align-items: center;
                          border-radius: 0px;
                        "
                      >
                        <v-icon size="40">mdi-lan-disconnect</v-icon>
                      </v-card>
                    </td>

                    <td style="width: 100px; max-width: 100px">
                      <div
                        class="d-flex justify-center"
                        :data-setup="'machine_' + item.tipo"
                      >
                        <v-card-text
                          class="pr-2 pl-0 py-0 text-center text-ellipsis font-weight-bold text-body-1 class-2linhas"
                          >{{ item.tipo }}</v-card-text
                        >
                      </div>
                    </td>

                    <td style="width: 200px">
                      <div
                        :data-setup="'machine_' + item.tipo"
                        class="text-center"
                      >
                        {{ retornaDataFormatada(item.dt_inicio) }}
                      </div>
                    </td>
                    <td style="width: 200px">
                      <div
                        :data-setup="'machine_' + item.tipo"
                        class="text-center"
                      >
                        {{ retornaDataFormatada(item.dt_fim) }}
                      </div>
                    </td>
                    <td style="min-width: 400px">
                      <div
                        class="d-flex align-center"
                        :data-setup="'machine_' + item.tipo"
                      >
                        <v-btn
                          variant="text"
                          size="35"
                          @click="ShowOrdemDeProducaoHistorico(item)"
                          color="secondary"
                          class="mr-1 ml-2 d-flex justify-right"
                        >
                          <v-icon
                            :color="item.tipo == 'Setup' ? 'black' : 'white'"
                            size="35"
                            >mdi-magnify</v-icon
                          >
                        </v-btn>

                        <v-btn
                          v-if="item.ordem_de_producao"
                          variant="text"
                          size="35"
                          @click="ShowDetalheOS(item)"
                          color="secondary"
                          class="mx-1"
                        >
                          <v-icon
                            :color="item.tipo == 'Setup' ? 'black' : 'white'"
                            size="35"
                            >mdi-folder-open</v-icon
                          >
                        </v-btn>
                        <v-card-text
                          class="pa-0 text-start text-ellipsis font-weight-bold text-body-1 class-2linhas"
                          >{{ item.ordem_de_producao }}</v-card-text
                        >
                      </div>
                    </td>
                    <td style="min-width: 150px; width: 150px">
                      <div
                        class="d-flex justify-center align-center"
                        :data-setup="'machine_' + item.tipo"
                      >
                        <v-card-text
                          class="px-0 py-0 text-center text-ellipsis font-weight-bold text-body-1 class-2linhas"
                          >{{ item.turno }}</v-card-text
                        >
                      </div>
                    </td>
                    <td style="width: 100px">
                      <div
                        class="d-flex align-center"
                        :data-setup="'machine_' + item.tipo"
                      >
                        <v-card-text
                          class="pr-2 pl-0 py-0 text-ellipsis font-weight-bold text-body-1 class-2linhas"
                          >{{ item.nome_operador }}</v-card-text
                        >
                      </div>
                    </td>
                    <td style="min-width: 150px">
                      <div
                        v-if="item.tipo === 'Parada'"
                        class="d-flex align-center"
                        :data-setup="'machine_' + item.tipo"
                      >
                        <v-btn
                          size="40"
                          flat
                          @click="ShowMotivoDeParada(item)"
                          color="transparent"
                        >
                          <v-icon size="35">mdi-magnify</v-icon>
                        </v-btn>
                        <v-card-text
                          class="pr-2 pl-0 py-0 text-ellipsis font-weight-bold text-body-1 class-2linhas"
                          >{{ item.desc_motivo_parada }}</v-card-text
                        >
                      </div>
                    </td>
                    <td style="min-width: 200px">
                      <v-tooltip :text="item.observacao">
                        <template v-slot:activator="{ props }">
                          <div
                            v-bind="props"
                            :data-setup="'machine_' + item.tipo"
                          >
                            <v-card-text
                              class="px-2 py-0 text-center text-ellipsis font-weight-bold text-body-1 class-2linhas"
                            >
                              {{ item.observacao }}
                            </v-card-text>
                          </div>
                        </template>
                      </v-tooltip>
                    </td>
                  </tr>
                </template>
              </v-data-table>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </v-card>
  </v-dialog>
</template>


<script>
import { useAppStore } from "../../../stores/app";
import { useAppOrdemProducao } from "../../../stores/appOrdemProducao";
import { useAppApontamento } from "../../../stores/appApontamento";
import { useAppMotivoDeParada } from "../../../stores/appMotivosdeParada";
import { DateRangePickerComponent } from "@syncfusion/ej2-vue-calendars";
import { formatDateSearch } from "../../utils/funcoes";
import { formatDate, apontamentos_filtrados } from "../../utils/funcoes";
import { useDisplay } from "vuetify";

export default {
  name: "historico",
  data() {
    const today = new Date();
    today.setHours(0, 0, 0, 0);
    this.defaultDateRange = [today, today];
    return {
      showModalHistorico: false,
      filter_apontamentos_faltando_informacoes: false,
      defaultDateRange: [today, today],
      selectedDates: null,
      display: useDisplay().name,
      noDataText: "SEM INFORMAÇÕES PARA EXIBIR",
      headers: [
        {
          title: "EDITAR",
          key: "tipo",
          sortable: false,
          align: "center",
          headerProps: { height: "30px" },
        },
        {
          title: "SENSOR",
          key: "status_sensor",
          sortable: false,
          align: "center",
          headerProps: { height: "30px" },
        },
        {
          title: "STATUS PRODUÇÃO",
          key: "tipo",
          sortable: false,
          align: "center",
          nowrap: true,
          headerProps: { height: "30px" },
        },
        {
          title: "INÍCIO",
          key: "dt_inicio",
          sortable: false,
          align: "center",
          headerProps: { height: "30px" },
        },
        {
          title: "FIM",
          key: "dt_fim",
          sortable: false,
          align: "center",
          headerProps: { height: "30px" },
          padding: "100px",
        },
        {
          title: "ORDEM DE PRODUÇÃO",
          key: "ordem_de_producao",
          sortable: false,
          align: "center",
          headerProps: { height: "30px" },
        },
        {
          title: "TURNO",
          key: "turno",
          sortable: false,
          align: "center",
          headerProps: { height: "30px" },
        },
        {
          title: "OPERADOR",
          key: "nome_operador",
          sortable: false,
          headerProps: { height: "30px" },
        },
        {
          title: "MOTIVO DE PARADA",
          key: "desc_motivo_parada",
          sortable: false,
          align: "center",
          nowrap: true,
          headerProps: { height: "30px" },
        },
        {
          title: "OBSERVAÇÃO",
          key: "machine_history_obs",
          sortable: false,
          align: "center",
          headerProps: { height: "30px" },
        },
      ],
    };
  },
  watch: {
    "appStore.showModalMotivoDeParada": {
      handler(newVal) {
        if (!newVal) {
          this.OnSearchHistoricos().then(async() => {
            if (useAppStore().filtro_apontamentos_faltando_informacoes) {
              await this.onFilterApontamentosSemInformacoes()
 }
          });
        }
      },
    },
  },
  "useAppOrdemProducao().showModal": {
    handler(newVal) {

      alert('caindo aqui ' + newVal)
      
      if (!newVal) {
        alert(newVal)
        this.OnSearchHistoricos().then(async() => {
            if (useAppStore().filtro_apontamentos_faltando_informacoes) {
              await this.onFilterApontamentosSemInformacoes()}
          });
      }
    },
  },
  "appApontamento.showModal": {
    handler(newVal) {
      if (!newVal) {
        this.OnSearchHistoricos().then(async() => {
            if (useAppStore().filtro_apontamentos_faltando_informacoes) {
              await this.onFilterApontamentosSemInformacoes()}
          });
      }
    },
    async "appStore.filtro_apontamentos_faltando_informacoes"(newVal) {
      if (newVal) {
        this.OnSearchHistoricos().then(async() => {
            if (useAppStore().filtro_apontamentos_faltando_informacoes) {
              await this.onFilterApontamentosSemInformacoes()}
          });
        }
    },
  },
  methods: {
    showCalendar() {
      this.$refs.dateRangePicker.show(); // Mostra o calendário
    },
    async ShowOrdemDeProducaoHistorico(data) {
      useAppApontamento().set_apontamento(data);
      useAppStore().view_apontamento_historico = true;
      useAppOrdemProducao().showModal = true;
    },
    async ShowMotivoDeParada(data) {
      useAppApontamento().selecionar_motivo_de_parada(data);
      useAppStore().showModalMotivoDeParada = true;
    },
    retornaDataFormatada(data) {
      return formatDate(data);
    },
    async onFilterApontamentosSemInformacoes() {
      const apontamentos_atual = useAppStore().apontamentos_historico;

      if (apontamentos_atual.length == 0) {
        return;
      }

      useAppStore().apontamentos_historico = [];

      apontamentos_filtrados(apontamentos_atual)
        .then((apontamentos_filtrados) => {
          useAppStore().apontamentos_historico = apontamentos_filtrados;
        })
        .catch(async (error) => {
          console.error("error apontamentos_filtrados", error);
          await useAppStore().filtrar_apontamentos(
            false,
            undefined,
            // 15,
            "detalhe_apontamento"
          );
        });
    },
    resetDateRange() {
      const today = new Date();
      today.setHours(0, 0, 0, 0);
      this.defaultDateRange = [today, today];
    },
    async onShowHistorico() {
      if (useAppStore().selectedDates.length == 0) {
        this.resetDateRange();
      } else {
        await this.OnSearchHistoricos();
        this.filter_apontamentos_faltando_informacoes = false;
        //15
        await useAppStore().filtrar_apontamentos(
          false,
          undefined,
          "onShowHistorico"
        );
        if (useAppStore().filtro_apontamentos_faltando_informacoes) {
          await this.onFilterApontamentosSemInformacoes();
        }
      }
    },
    async onVoltar() {
      const today = new Date();
      today.setHours(0, 0, 0, 0);
      this.defaultDateRange = [today, today];
     
      await useAppStore().filtrar_apontamentos(
        useAppStore().filtro_apontamentos_faltando_informacoes,
        undefined,
        "onShowHistorico"
      );
      
      useAppStore().showModalHistorico = false;
      
      this.resetDateRange();
    },
    CustomizaLinha(tipo) {
      let style = ``;

      switch (tipo) {
        case "Produção":
          return `${style + "color: white; background-color: #43A047"}`;
        case "Parada":
          return `${style + "color: white; background-color: #D50000"}`;
        case "Setup":
          return `${style + "color: black; background-color: #F9A825"}`;
        case "Manutenção":
          return `${style + "color: white; background-color: #1E88E5"}`;
        default:
          return `${style + "color: white; background-color: #f8d7da"}`;
      }
    },
    async OnSearchHistoricos() {
      if (useAppStore().selectedDates.length != 0) {
        const dt_inicio = formatDateSearch(
          useAppStore().selectedDates[0]
        ).split(" ")[0];
        const dt_fim = formatDateSearch(useAppStore().selectedDates[1]).split(
          " "
        )[0];

        try {
          useAppStore().apontamentos_historico =
            await useAppStore().get_all_filter_by_date_apontamento(
              dt_inicio,
              dt_fim
            );
        } catch (error) {
          console.log("error", error);
        }
      } else {
        //15
        useAppStore().filtrar_apontamentos(
          false,
          undefined,
          "OnSearchHistoricos"
        );
      }
    },
    applyDateRangeFormat(event) {
      const allowedKeys = [
        "0",
        "1",
        "2",
        "3",
        "4",
        "5",
        "6",
        "7",
        "8",
        "9",
        "Backspace",
        "Delete",
        "ArrowLeft",
        "ArrowRight",
        "Tab",
      ];

      if (!allowedKeys.includes(event.key)) {
        event.preventDefault();
        return;
      }

      const input = event.target;
      let value = input.value.replace(/\D/g, ""); // Remove todos os caracteres não numéricos

      // Aplicar formatação: "dd/MM/yyyy - dd/MM/yyyy"
      if (value.length > 0 && value.length <= 2) {
        value = value.slice(0, 2);
      } else if (value.length > 2 && value.length <= 4) {
        value = value.slice(0, 2) + "/" + value.slice(2, 4);
      } else if (value.length > 4 && value.length <= 8) {
        value =
          value.slice(0, 2) + "/" + value.slice(2, 4) + "/" + value.slice(4, 8);
      } else if (value.length > 8 && value.length <= 10) {
        value =
          value.slice(0, 2) +
          "/" +
          value.slice(2, 4) +
          "/" +
          value.slice(4, 8) +
          " - " +
          value.slice(8);
      } else if (value.length > 10 && value.length <= 12) {
        value =
          value.slice(0, 2) +
          "/" +
          value.slice(2, 4) +
          "/" +
          value.slice(4, 8) +
          " - " +
          value.slice(8, 10) +
          "/" +
          value.slice(10);
      } else if (value.length > 12) {
        value =
          value.slice(0, 2) +
          "/" +
          value.slice(2, 4) +
          "/" +
          value.slice(4, 8) +
          " - " +
          value.slice(8, 10) +
          "/" +
          value.slice(10, 12) +
          "/" +
          value.slice(12, 16);
      }

      input.value = value;
    },
    onDateChange(args) {
      useAppStore().selectedDates = args.value;
      if (args.length > 0) {
        this.startDate = formatDate(args.value[0]);
        this.endDate = formatDate(args.value[1]);
      }
    },
    ShowApontamento(data) {
      useAppApontamento().titulo_modal = `INÍCIO DO APONTAMENTO: ${formatDate(
        data.dt_inicio
      )} - FIM DO APONTAMENTO: ${formatDate(data.dt_fim)}`;
      useAppApontamento().set_apontamento(data);
      useAppApontamento().showModal = true;
    },
    async ShowDetalheOS(data) {
      await useAppOrdemProducao().listar_files_ordem_producao(
        data.ordem_de_producao
      );
      useAppStore().showModalObsDetalhes = true;
    },
    statusIcon(status_sensor) {
      //
      return status_sensor === "ACTIVE"
        ? "mdi-lan-connect"
        : "mdi-lan-disconnect";
    },
    verify_status_sensor(status_sensor = false) {
      return status_sensor === "ACTIVE" || status_sensor === "IDLE";
    },
    status_color(status_sensor = null) {
      if (status_sensor === "ACTIVE") {
        return "#43A047"; // Verde
      } else if (status_sensor === "IDLE") {
        return "#D50000"; // Vermelho
      } else {
        return "#37474F"; // Cinza
      }
    },
    statusIconColor(status_sensor) {
      return status_sensor === "ACTIVE" ? "green" : "red";
    }, //
  },
  computed: {
    appStore() {
      return useAppStore();
    },
    appApontamento() {
      return useAppApontamento();
    },
    appOp() {
      if (!useAppOrdemProducao().showModal) {
        this.OnSearchHistoricos().then(async() => {
            if (useAppStore().filtro_apontamentos_faltando_informacoes) {
              await this.onFilterApontamentosSemInformacoes()}
          });
      }

      return useAppOrdemProducao();
    },
  },
  components: {
    "ejs-daterangepicker": DateRangePickerComponent,
  },
};
</script>

<style scoped>
::v-deep .v-checkbox input {
  width: 60px !important;
}

/* feito para aumentar a area de click do checkbox */

::v-deep .v-selection-control label {
  margin-bottom: 0px !important;
}

.no-border .e-grid {
  border: none;
}

.class-2linhas {
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
  white-space: normal;
}

::v-deep(.v-data-table thead) {
  background-color: #121935 !important;
  position: sticky;
  top: 0;
  z-index: 10;
  color: white;
  font-weight: bold;
}

::v-deep(.v-pagination__list),
::v-deep(.v-divider) {
  margin: 0 !important;
}
</style>