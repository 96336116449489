<template>
    <v-dialog v-model="appFuncionarios.showModal" max-width="60%" persistent transition="dialog-bottom-transition">
        <v-card >
            <v-toolbar color="primary" >
                <v-btn @click="appFuncionarios.showModal = false"  color="secondary" variant="elevated"
                    prepend-icon="mdi-arrow-left-circle">VOLTAR</v-btn>

                <v-toolbar-title>OPERADORES</v-toolbar-title>

                <v-spacer></v-spacer>
            </v-toolbar>
            <v-card-text>
                    <v-row>
                        <v-text-field density="compact" v-model="filter_funcionarios"  id="filter_operador" label="FILTRAR OPERADORES" class="mt-2 mb-0 pl-1 pr-1 pt-0 pb-0"
                               style="width: 100%;font-weight: bold;"  variant="solo" bg-color="secondary"
                            placeholder="FILTRAR OPERADORES" ></v-text-field>
                    </v-row>
                    <v-row class="pt-0 pb-1 mt-1" justify="space-between" v-for="(funcionario, index) in resourceFuncionarios"
                    :key="index">
                            <v-col cols="12" class="pt-1 pb-1 mt-1">
                                <v-btn class="mt-1" color="primary" height="60" data-cy="btn-selecionar-operador"
                                    @click="SelecionarFuncionario(funcionario)" style="width: 100%;">{{ funcionario.nome
                                    }}</v-btn>
                            </v-col>
                    </v-row>
            </v-card-text>
        </v-card>
    </v-dialog>
</template>

<script>

import { useAppFuncionarios } from '../../../stores/appFuncionarios';

import { useAppStore } from '../../../stores/app';
import { useAppApontamento } from '../../../stores/appApontamento';

export default {
    name: "funcionarios",
    data() {
        return {
            filter_funcionarios : ''
        }
    },
    methods: {
        async SelecionarFuncionario(funcionario) {
            useAppStore().load_actions = {message : "SALVANDO FUNCIONARIO", showMessage : true};
            
            const json_update = {
                    operador : funcionario.name
                }
           
            if (!useAppStore().view_apontamento_historico) {
                useAppStore().selecionar_funcionario(funcionario);
                await useAppApontamento().update_apontamento(useAppStore().apontamento_atual.name, json_update)

                useAppFuncionarios().showModal = false;
            }
            else {
                useAppApontamento().selecionar_funcionario(funcionario);
                await useAppApontamento().update_apontamento(useAppApontamento().resourceApontamento.name, json_update)     
                useAppFuncionarios().showModal = false;
            }

            useAppStore().load_actions.showMessage = false;
        },
        handleKeydown(event) {
            // Verifica se a tecla pressionada é ESC
            if (event.key === 'Escape') {
                useAppFuncionarios().showModal = false; 
            }
        }
    },
    computed: {
        appFuncionarios() {
            return useAppFuncionarios();
        },
        resourceFuncionarios() {
            // const filter = this.filter_funcionarios.toUpperCase();
            return useAppFuncionarios().resourceFuncionarios;
            // .filter(funcionario =>
            //     funcionario.nome.toUpperCase().includes(filter)
            // );
        },
        
    },
    mounted() {
        useAppFuncionarios().listar_funcionarios();

        window.addEventListener ("keydown", this.handleKeydown)
    },
}

</script>
