<template>
    <v-dialog v-model="appLoadMessage.showMessage" max-width="600" persistent>
        <v-list class="py-2" bg-color="primary" color="primary" elevation="12" rounded="lg">
            <v-list-item prepend-icon="$vuetify-outline" :title="appLoadMessage.message">
                <template v-slot:prepend>
                    <div class="pe-4">
                        <v-icon color="primary" size="x-large"></v-icon>
                    </div>
                </template>

                <template v-slot:append>
                    <v-progress-circular color="white" indeterminate="disable-shrink" size="16"
                        width="2"></v-progress-circular>
                </template>
            </v-list-item>
        </v-list>
    </v-dialog>
</template>

<script>

import { useAppStore } from '../../../stores/app';

export default {
    name: 'Load',
    data() {
        return {

        }
    },
    computed: {
       appLoadMessage() {
           return useAppStore().load_actions
       },
    },
}

</script>