<template>
    <v-container fluid style="width: 100%;">
        <v-card style="height: calc(100vh - 50px); overflow-y: auto !important;">
            <v-row justify="start;" class="p-3">
                <v-col cols="12" sm="6" md="4">
                    <v-card class="d-flex flex-column justify-end align-center" style="height: 150px;"
                        color="primary" @click="goParetoParadas">
                        <v-icon icon="mdi-chart-bar" size="100"></v-icon>
                        <v-card-title class="text-white text-center">PARETO DAS PARADAS</v-card-title>
                    </v-card>
                </v-col>
                <v-col cols="12" sm="6" md="4">
                    <v-card class="d-flex flex-column justify-end align-center" style="height: 150px;"
                        color="primary" @click="goDispResumida">
                        <v-icon icon="mdi-chart-donut" size="100"></v-icon>
                        <v-card-title class="text-white text-center">DISPONIBILIDADE
                            RESUMIDA</v-card-title>
                    </v-card>
                </v-col>
                <v-col cols="12" sm="6" md="4">  
                    <v-card @click="goMaquinasStatus" class="d-flex flex-column justify-end align-center"
                        style="height: 150px;" color="primary">
                        <v-icon icon="mdi-cog-box" size="100"></v-icon>
                        <v-card-title class="text-white text-center">MÁQUINA POR STATUS</v-card-title>
                    </v-card>
                </v-col>
                <v-col cols="12" sm="6" md="4">
                    <v-card @click="goDesempenho" class="d-flex flex-column justify-end align-center" style="height: 150px;"
                        color="primary" >
                        <v-icon icon="mdi-finance" size="100"></v-icon>
                        <v-card-title class="text-white text-center">DESEMPENHO DA EMPRESA</v-card-title>
                    </v-card>
                </v-col>
            </v-row>
        </v-card>
    </v-container>
</template>

<script>
import { useAppCharts } from '../../../stores/appCharts';
import { useRouter, useRoute } from "vue-router";

import { useAppFabricaAtual } from '../../../stores/appFabricaAtual';

export default {
    name: "view_charts",

    data() { return { 
        ialog: true, 
        router: useRouter(),
    } },

    methods: {
        goDesempenho() {
            this.router.push('/app/apontamento-iot/dash/desempenho/')
        },
        goParetoParadas() {
            this.router.push('/app/apontamento-iot/dash/paretoParadas/')
        },
        goMaquinasStatus() {
            useAppFabricaAtual().listar_recursos_abertos().then(() => {
                this.router.push('/app/apontamento-iot/dash/maquinasStatus/')
            })
        },
        goDispResumida() {
            this.router.push('/app/apontamento-iot/dash/dispResumida/')
        }
    },
    computed: { appCharts() { return useAppCharts(); } },
}
</script>

<style></style>