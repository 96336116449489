<template>
  <v-row class="pt-0 pb-0">
    <v-col cols="12">
      <v-card color="#EEEEEE" rounded="0">
        <HeaderApontamento/>
        <v-row>
          <v-col cols="12" class="d-md-none d-sm-block mt-1 pb-0 pt-xs-3 pt-sm-0 px-4">
              <v-card color="primary" class="d-flex" >
                  <v-card-text class="text-caption text-md-body-2 font-weight-black  text-break py-1"> <v-icon
                          icon="mdi-gauge-full"></v-icon> DISPON.: Hoje: {{ appStore.maquina_sel.disponibilidade_diaria }}%</v-card-text>
                  <v-card-text class="text-caption font-weight-black  text-md-body-2 text-break py-1"> TURNO:
                      {{ }}</v-card-text>
              </v-card>
          </v-col>
          <v-col cols="3" md="2" class="d-none d-md-flex text-center flex-column align-center px-0 pb-1 pt-0">
            <gaugeDisponibilidade />
          </v-col>
          <v-col cols="12" sm="5" md="3">
            <eventosApontamento />
          </v-col>
          <v-col cols="12" sm="7" md="7">
            <infoprodApontamento />
          </v-col>
        </v-row>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import HeaderApontamento from './headerApontamento.vue';

import gaugeDisponibilidade from './gaugeDisponibilidade.vue';

import eventosApontamento from './eventosApontamento.vue';

import infoprodApontamento from './infoprodApontamento.vue';

import { useAppStore } from '../../../stores/app';

export default {
  name: "statusMachine",
  components: {
    HeaderApontamento,
    gaugeDisponibilidade,
    eventosApontamento,
    infoprodApontamento
  },
  data() {
    return {
      tempExecucao: '00:00:00'
    };
  },
  computed: {
    appMachine() {
      return useAppMachine()
    },
    appStore() {
      return useAppStore();
    }
  },
};
</script>
