<template>
  <div class="detalheapontamento">
    <v-tabs
      v-model="tab"
      bg-color="primary"
      color="white"
      slider-color="#90A4AE"
      height="30"
      selected-class="color-tab"
    >
      <v-tab
        value="historico"
        class="font-weight-bold"
        style="width: 40%; min-width: 20%; max-width: 350px; height: 100%"
        >HISTÓRICO</v-tab
      >
    </v-tabs>
    <v-tabs-window v-model="tab" style="width: 100%">
      <v-tab-item value="historico">
        <v-row id="container-events-mp" cols="12" class="pt-0">
          <v-col cols="12" xs="12" sm="12" md="12" id="v-col-historico">
            <v-card color="#EEEEEE" class="cardEvents" rounded="0">
              <v-toolbar height="45" id="toolbarHist" color="#37474F">
                <div class="d-flex justify-center align-center">
                  <v-btn
                    @click="onShowHistorico"
                    color="#EEEEEE"
                    variant="flat"
                    text
                    prepend-icon="mdi mdi-magnify"
                  >
                    <v-card-text
                      v-if="
                        display == 'lg' || display == 'xl' || display == 'xxl'
                      "
                      class="pa-0"
                      >HISTÓRICOS ANTERIORES</v-card-text
                    >
                    <v-card-text class="pa-0" v-else> HIST. ANT.</v-card-text>
                  </v-btn>
                  <v-checkbox
                    class="pt-0 pb-0 mr-5"
                    label="FALTANDO INFORMAÇÕES"
                    hide-details="false"
                    style="margin-bottom: none"
                    v-model="appStore.filtro_apontamentos_faltando_informacoes"
                  ></v-checkbox>
                </div>
              </v-toolbar>
              <div style="background-color: #e1e1e3">
                <v-data-table-virtual
                  :headers="headers"
                  :no-data-text="noDataText"
                  :items="appStore.apontamentos_historico"
                  :height="
                    display == 'xl' || display == 'xxl'
                      ? 'calc(100vh - 370px)'
                      : display == 'lg'
                      ? 'calc(100vh - 330px)'
                      : '60vh'
                  "
                  width="100%"
                  id="histHoje"
                >
                  <template v-slot:no-data>
                    <v-card-text class="text-h6 font-weight-bold">
                      <v-icon
                        color="black"
                        class="mb-1"
                        icon="mdi-emoticon-cool"
                      ></v-icon>
                      {{ noDataText }}
                    </v-card-text>
                  </template>
                  <template v-slot:item="{ item }">
                    <tr
                      :style="CustomizaLinha(item.tipo)"
                      class="font-weight-bold boder-sm border-opacity-100 border-bcinputs"
                      style="border-color: #fff !important"
                    >
                      <td style="width: 30px">
                        <div class="d-flex justify-center content-center">
                          <v-icon
                            @click="ShowApontamento(item)"
                            icon="mdi mdi-file-edit-outline"
                            size="35"
                            style="cursor: pointer"
                          ></v-icon>
                        </div>
                      </td>
                      <td style="color: #fff; width: 30px">
                        <v-card
                          v-if="verify_status_sensor(item.status_sensor)"
                          :color="status_color(item.status_sensor)"
                          class="d-flex justify-center"
                          style="
                            min-width: 70px;
                            height: 61px;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            border-radius: 0px;
                          "
                        >
                          <v-icon size="40">mdi-lan-connect</v-icon>
                        </v-card>
                        <v-card
                          v-else
                          :color="'#37474F'"
                          style="
                            min-width: 70px;
                            height: 61px;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            border-radius: 0px;
                          "
                        >
                          <v-icon size="40">mdi-lan-disconnect</v-icon>
                        </v-card>
                      </td>

                      <td style="width: 100px; max-width: 100px">
                        <div
                          class="d-flex justify-center"
                          :data-setup="'machine_' + item.tipo"
                        >
                          <v-card-text
                            class="pr-2 pl-0 py-0 text-center text-ellipsis font-weight-bold text-body-1 class-2linhas"
                            >{{ item.tipo }}</v-card-text
                          >
                        </div>
                      </td>

                      <td style="width: 200px">
                        <div
                          :data-setup="'machine_' + item.tipo"
                          class="text-center"
                        >
                          {{ retornaDataFormatada(item.dt_inicio) }}
                        </div>
                      </td>
                      <td style="width: 200px">
                        <div
                          :data-setup="'machine_' + item.tipo"
                          class="text-center"
                        >
                          {{ retornaDataFormatada(item.dt_fim) }}
                        </div>
                      </td>
                      <td style="min-width: 350px">
                        <div
                          class="d-flex align-center"
                          :data-setup="'machine_' + item.tipo"
                        >
                          <v-btn
                            variant="text"
                            size="35"
                            @click="ShowOrdemDeProducaoHistorico(item)"
                            color="secondary"
                            class="mr-1 ml-2 d-flex justify-right"
                          >
                            <v-icon
                              :color="item.tipo == 'Setup' ? 'black' : 'white'"
                              size="35"
                              >mdi-magnify</v-icon
                            >
                          </v-btn>

                          <v-btn
                            v-if="item.ordem_de_producao"
                            variant="text"
                            size="35"
                            @click="ShowDetalheOS(item)"
                            color="secondary"
                            class="mx-1"
                          >
                            <v-icon
                              :color="item.tipo == 'Setup' ? 'black' : 'white'"
                              size="35"
                              >mdi-folder-open</v-icon
                            >
                          </v-btn>
                          <v-card-text
                            class="pa-0 text-start text-ellipsis font-weight-bold text-body-1 class-2linhas"
                            >{{ item.ordem_de_producao }}</v-card-text
                          >
                        </div>
                      </td>
                      <td style="min-width: 150px; width: 150px">
                        <div
                          class="d-flex justify-center align-center"
                          :data-setup="'machine_' + item.tipo"
                        >
                          <v-card-text
                            class="px-0 py-0 text-center text-ellipsis font-weight-bold text-body-1 class-2linhas"
                            >{{ item.turno }}</v-card-text
                          >
                        </div>
                      </td>
                      <td style="width: 100px">
                        <div
                          class="d-flex align-center"
                          :data-setup="'machine_' + item.tipo"
                        >
                          <v-card-text
                            class="pr-2 pl-0 py-0 text-ellipsis font-weight-bold text-body-1 class-2linhas"
                            >{{ item.nome_operador }}</v-card-text
                          >
                        </div>
                      </td>
                      <td style="min-width: 150px">
                        <div
                          v-if="item.tipo === 'Parada'"
                          class="d-flex align-center"
                          :data-setup="'machine_' + item.tipo"
                        >
                          <v-btn
                            size="40"
                            flat
                            @click="ShowMotivoDeParada(item)"
                            color="transparent"
                          >
                            <v-icon size="35">mdi-magnify</v-icon>
                          </v-btn>
                          <v-card-text
                            class="pr-2 pl-0 py-0 text-ellipsis font-weight-bold text-body-1 class-2linhas"
                            >{{ item.desc_motivo_parada }}</v-card-text
                          >
                        </div>
                      </td>
                      <td style="min-width: 200px">
                        <v-tooltip :text="item.observacao">
                          <template v-slot:activator="{ props }">
                            <div
                              v-bind="props"
                              :data-setup="'machine_' + item.tipo"
                            >
                              <v-card-text
                                class="px-2 py-0 text-center text-ellipsis font-weight-bold text-body-1 class-2linhas"
                              >
                                {{ item.observacao }}
                              </v-card-text>
                            </div>
                          </template>
                        </v-tooltip>
                      </td>
                    </tr>
                  </template>
                </v-data-table-virtual>
              </div>
            </v-card>
          </v-col>
        </v-row>
      </v-tab-item>
    </v-tabs-window>
  </div>
</template>

<script scoped>
import { useAppMaquinas } from "../../../stores/appMaquinas";

import { useAppApontamento } from "../../../stores/appApontamento";

import { useAppMotivoDeParada } from "../../../stores/appMotivosdeParada";

import { useAppStore } from "../../../stores/app";

import { useAppOrdemProducao } from "../../../stores/appOrdemProducao";

import { formatDate, apontamentos_filtrados } from "../../utils/funcoes";

import { useDisplay } from "vuetify";

export default {
  name: "detalheApontamento",
  mounted() {
    useAppMaquinas().listar_maquinas();
  },
  computed: {
    appStore() {
      return useAppStore();
    },
  },
  watch: {
    async "appStore.filtro_apontamentos_faltando_informacoes"(newValue) {
      if (newValue) {
        const apontamentos_atual = useAppStore().apontamentos_historico;

        if (apontamentos_atual.length == 0) {
          return;
        }

        console.log('aponatamentos atual', apontamentos_atual.length);
        useAppStore().apontamentos_historico = [];

        apontamentos_filtrados(apontamentos_atual)
          .then((apontamentos_filtrados) => {
            useAppStore().apontamentos_historico = apontamentos_filtrados;
          })
          .catch(async (error) => {
            console.error("error apontamentos_filtrados", error);
            await useAppStore().filtrar_apontamentos(
              false,
              // 15,
              undefined,
              "detalhe_apontamento"
            );
          });
      } else {
        await useAppStore().filtrar_apontamentos(
          false,
          undefined,
          // 15,
          "detalhe_apontamento"
        );
      }
    },
  },
  methods: {
    async ShowOrdemDeProducaoHistorico(data) {
      useAppApontamento().set_apontamento(data);
      useAppStore().view_apontamento_historico = true;
      useAppOrdemProducao().showModal = true;
    },
    async ShowMotivoDeParada(data) {
      useAppApontamento().selecionar_motivo_de_parada(data);
      // await useAppMotivoDeParada().listar_motivos_de_parada();
      useAppStore().showModalMotivoDeParada = true;
    },
    retornaDataFormatada(data) {
      return formatDate(data);
    },
    async ShowDetalheOS(data) {
      await useAppOrdemProducao().listar_files_ordem_producao(
        data.ordem_de_producao
      );
      useAppStore().showModalObsDetalhes = true;
    },
    onShowHistorico() {
      useAppStore().showModalHistorico = true;
    }, //
    ShowApontamento(data) {
      useAppApontamento().titulo_modal = `INÍCIO DO APONTAMENTO: ${formatDate(
        data.dt_inicio
      )} - FIM DO APONTAMENTO: ${formatDate(data.dt_fim)}`;
      useAppApontamento().set_apontamento(data);
      useAppApontamento().showModal = true;
    },
    CustomizaLinha(tipo) {
      let style = ``;

      switch (tipo) {
        case "Produção":
          return `${style + "color: white; background-color: #43A047"}`;
        case "Parada":
          return `${style + "color: white; background-color: #D50000"}`;
        case "Setup":
          return `${style + "color: black; background-color: #F9A825"}`;
        case "Manutenção":
          return `${style + "color: white; background-color: #1E88E5"}`;
        default:
          return `${style + "color: white; background-color: #f8d7da"}`;
      }
    },

    verify_status_sensor(status_sensor = false) {
      return status_sensor === "ACTIVE" || status_sensor === "IDLE";
    },

    status_color(status_sensor = null) {
      if (status_sensor === "ACTIVE") {
        return "#43A047"; // Verde
      } else if (status_sensor === "IDLE") {
        return "#D50000"; // Vermelho
      } else {
        return "#37474F"; // Cinza
      }
    },
  },
  data() {
    return {
      tab: "historico",
      FiltroSelecionado: localStorage.getItem("TypesInformation"),
      ShowInfosFaltando: false,
      noDataText: "SEM INFORMAÇÕES PARA EXIBIR",
      display: useDisplay().name,
      headers: [
        {
          title: "EDITAR",
          key: "tipo",
          sortable: false,
          align: "center",
          headerProps: { height: "30px" },
        },
        {
          title: "SENSOR",
          key: "status_sensor",
          sortable: false,
          align: "center",
          headerProps: { height: "30px" },
        },
        {
          title: "STATUS PRODUÇÃO",
          key: "tipo",
          sortable: false,
          align: "center",
          nowrap: true,
          headerProps: { height: "30px" },
        },
        {
          title: "INÍCIO",
          key: "dt_inicio",
          sortable: false,
          align: "center",
          headerProps: { height: "30px" },
        },
        {
          title: "FIM",
          key: "dt_fim",
          sortable: false,
          align: "center",
          headerProps: { height: "30px" },
          padding: "100px",
        },
        {
          title: "ORDEM DE PRODUÇÃO",
          key: "ordem_de_producao",
          sortable: false,
          align: "center",
          headerProps: { height: "30px" },
        },
        {
          title: "TURNO",
          key: "turno",
          sortable: false,
          align: "center",
          headerProps: { height: "30px" },
        },
        {
          title: "OPERADOR",
          key: "nome_operador",
          sortable: false,
          headerProps: { height: "30px" },
        },
        {
          title: "MOTIVO DE PARADA",
          key: "desc_motivo_parada",
          sortable: false,
          align: "center",
          nowrap: true,
          headerProps: { height: "30px" },
        },
        {
          title: "OBSERVAÇÃO",
          key: "machine_history_obs",
          sortable: false,
          align: "center",
          headerProps: { height: "30px" },
        },
      ],
    };
  },
};
</script>

<style scoped>
/* o ::v-deep força o sistema a usar a estilização do vuetify */
::v-deep #toolbarHist > .v-toolbar__content {
  display: flex !important;
  padding: 0 !important;
  justify-content: end !important;
}

::v-deep .v-checkbox input {
  width: 60px !important;
}

/* feito para aumentar a area de click do checkbox */

::v-deep .v-selection-control label {
  margin-bottom: 0px !important;
}

/*Tirando o espaço do label do checkbox */

.e-grid .e-row:hover {
  opacity: 0.9 !important;
}

.class-2linhas {
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
  white-space: normal;
}

.cardEvents {
  width: 100%;
}

::v-deep(.v-data-table thead) {
  background-color: #121935 !important;
  color: white;
  font-weight: bold;
  position: sticky;
  top: 0;
  z-index: 10;
}
</style>
