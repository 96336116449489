<template>
  <v-app-bar color="primary" class="pt-0 pb-0" height="50">
    <small class="ml-2" style="">
      <img src="/assets/nxlite/images/logo_nxerp-04.png" alt="Logo NX" style="max-width: 7%;">
    </small>
   
    <v-spacer></v-spacer>
    <small class="mr-2">versao-1.0.0.0</small>
    <v-btn v-if="currentPath === '/app/apontamento-iot' || showButtons" color="#43A047" variant="elevated" prepend-icon="mdi-monitor-dashboard" class="mr-4"
      @click="goDashboards">MEUS DASHBOARDS</v-btn>
    <v-btn v-if="currentPath === '/app/apontamento-iot/dashboards' || showButtons" color="#43A047" variant="elevated" prepend-icon="mdi-home" class="mr-4"
      @click="goHome">HOME</v-btn>
  </v-app-bar>
</template>

<script>
import { useAppStore } from "../stores/app";
import { useAppCharts } from "../stores/appCharts"
import { useRouter, useRoute } from "vue-router";

export default {
  name: "appNavbar",
  data() { return { 
    count: 0, 
    router: useRouter(), 
    route: useRoute(),  
    currentPath: '',
    showButtons: false
  } },
  computed: { 
      appStore() { return useAppStore(); },
  },
  methods: {
    onlogout() { useAppStore().reset_app() },
    goDashboards() { this.router.push('/app/apontamento-iot/dashboards') },
    goHome() { this.router.push('/app/apontamento-iot') },
    open_recurso() { useAppCharts().open_disponibilidade_resumida = true },
    
  },
  watch: {
    // O Vue 3 usa reatividade, e o router.currentRoute é um objeto reativo. Quando você acessa router.currentRoute.path diretamente fora de um contexto reativo (como um console.log no código), ele pode não refletir o valor atualizado imediatamente, porque o Vue ainda está processando as mudanças de rota.
    // para a solução ou conferir o router.currentRoute, acesse apenas o router, e no console do browser vc acessa os valores.

    '$route' (newRoute) { //quando temos uma variável ou objeto reativo para observarmos ele no watch devemos colocar entre '' como uma string a
      
    this.currentPath = newRoute.path;

      let checkURL = this.currentPath.includes('/dash/')

      if (checkURL) {
        this.showButtons = true
      } else {
        this.showButtons = false
      }
    }
  },
  created() {
    this.currentPath = this.$route.path;
  },


};
</script>
