<template>
    <v-container fluid>
        <v-card :height="display == 'lg' || display == 'xl' || display == 'xxl' ? 'calc(100vh - 55px)' : ''">
            <toolbarCharts @toolbarFiltros="toolbarFiltros"  @onGetGraficInformation="onGetGraficInformation" :type="type" :filtroDesempenho="true"/>
            <multiple_charts_desempenho_da_empresa :dataDesempenho="dataDesempenho" :meta="selectedMeta"/>
        </v-card>
    </v-container>
</template>

<script>
import { useAppCharts } from "../../../stores/appCharts";
import multiple_charts_desempenho_da_empresa from '../charts/multiple_charts.desempenho_da_empresa.vue'
import toolbarCharts from '../charts/toolbar/index.vue'

import { VNumberInput } from 'vuetify/labs/VNumberInput'
import { useDisplay } from "vuetify";

export default {
    name: 'desempenho_da_empresa',
    components: {
        multiple_charts_desempenho_da_empresa,
        toolbarCharts,
        VNumberInput
    },
    
    data() {
        return {
            dataDesempenho: null,
            selectedStartDate: [],
            selectedEndDate: [],
            selectedMachine: [],
            selectedShift: [],
            selectedType: '',
            selectedGroupBy: '',
            selectedMeta: 0,
            display: useDisplay().name
        };
    },

    mounted() {
        this.getDataDesempenho()
    },
    computed: {
        appCharts() {
            return useAppCharts();
        },
    },

    methods: {
        onGetGraficInformation() {

            this.dataDesempenho = null,
            this.getDataDesempenho()
        },

        toolbarFiltros(pFiltro, pValue) {
            switch (pFiltro) {
                case 'machine':
                    this.selectedMachine = pValue
                    break;
                case 'shift':
                    this.selectedShift = pValue
                    break;
                case 'startDate':
                    this.selectedStartDate = pValue
                    break
                case 'endDate':
                    this.selectedEndDate = pValue
                    break
                case 'type': 
                    this.selectedType = pValue
                    break
                case 'groupBy': 
                    this.selectedGroupBy = pValue
                    break
                case 'meta':
                    this.selectedMeta = pValue
                    break
            };
        },

        getDataDesempenho() {
            this.appCharts.desempenho_empresa(this.selectedMachine, this.selectedShift, this.selectedStartDate, this.selectedEndDate, this.selectedType, this.selectedGroupBy).then(() => {                
                this.$nextTick(() => {
                    this.dataDesempenho = this.appCharts.resourceDesempenho
                })
            })
        }
    },
};
</script>
