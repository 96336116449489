<template>
    <v-dialog v-model="appMaquinas.showModal" max-width="60%" persistent transition="dialog-bottom-transition">
        <v-card>
            <v-toolbar color="primary">
                <v-toolbar-title>MÁQUINAS</v-toolbar-title>

                <v-spacer></v-spacer>
            </v-toolbar>
            <v-card-text>
                <v-row>
                    <v-text-field id="filter_maquinas" density="compact" v-model="filter_maquinas" label="FILTRAR MAQUINAS"
                        class="mt-2 mb-0 pl-1 pr-1 pt-0 pb-0" style="width: 100%;font-weight: bold;" variant="solo"
                        bg-color="secondary" placeholder="FILTRAR MAQUINAS"></v-text-field>
                </v-row>
                <v-row class="pt-0 pb-1 mt-1" justify="space-between" v-for="(maquina, index) in resourceMaquinas"
                    :key="index">
                    <v-col cols="12" class="pt-1 pb-1 mt-1">
                        <v-btn class="mt-1" :data-cy="`btn-machine`" color="primary" height="60" @click="SelecionarMaquina(maquina)"
                            style="width: 100%;">{{ maquina.nome }}</v-btn>
                    </v-col>
                </v-row>
            </v-card-text>
        </v-card>
    </v-dialog>
</template>

<script>
import { useAppApontamento } from '../../../stores/appApontamento';
import { useAppMaquinas } from '../../../stores/appMaquinas';
import { useAppStore } from '../../../stores/app';

export default {
    name: "maquinas",

    data() { return { filter_maquinas: '' } },

    methods: {
        reset_app(newValue) {
            if (newValue) {
                useAppStore().reset_app();
            }
        },
        fotoTemplate(data) { return `<img src="${data.foto}" alt="Foto" style="width: 100%; height: auto;" />`; },
        IniciaisFunc(data) { return data.split(' ').map((n) => n[0]).join(''); },

        async SelecionarMaquina(maquina) {
            const json_update = { maquina: maquina.name }

            if (!useAppStore().view_apontamento_historico) {
                useAppStore().selecionar_maquina(maquina);
                useAppApontamento().update_apontamento(useAppStore().apontamento_atual.name, json_update)
                useAppMaquinas().showModal = false;
            } else {
                useAppStore().selecionar_maquina(maquina);
                useAppApontamento().update_apontamento(useAppApontamento().resourceApontamento.name, json_update)
                useAppMaquinas().showModal = false;
            }
        }
    },

    computed: {
        appMaquinas() { return useAppMaquinas(); },
        appStore() { return useAppStore(); },

        resourceMaquinas() {
            const filter = this.filter_maquinas.toUpperCase();
            return useAppMaquinas().resourceMaquinas.filter(maquina => maquina.nome.toUpperCase().includes(filter));
        },
    },

    mounted() { 
        useAppMaquinas().listar_maquinas(); console.log('caiu: useAppMaquinas().listar_maquinas()') },
    watch : {
        "appMaquinas.showModal" : function(newValue) {
            this.reset_app(newValue);
        }

    }

}
</script>