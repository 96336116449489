import { Maquinas, Apontamentos } from "./../app/common/interfaceApp";
import { defineStore } from "pinia";
import { useAppStore } from "./app";
import { useAppCharts } from "./appCharts";

import { apontamentos_filtrados } from "../app/utils/funcoes"

export const useAppMaquinas = defineStore({
  id: "appMaquinas_iot",
  state: (): {
    resourceMaquinas: Maquinas[],
    showModalMaquina: boolean,
  } => ({
    resourceMaquinas: [],
    showModalMaquina: false,
  }),

  getters: {},
  actions: {
    async get_historico_apontamentos(recurso_name: string) {
      try {
        const res = await frappe.call(
          "nxlite.nx_producao.page.apontamento_iot.controller.get_historico_recurso_fechado",
          { recurso_name: recurso_name }
        );

        useAppStore().apontamentos_historico = []
        useAppStore().apontamentos_historico = res.message


        const apontamentos = useAppStore().apontamentos_historico

        if (useAppStore().filtro_apontamentos_faltando_informacoes) {
          apontamentos_filtrados(apontamentos).then((apontamentos_filtrados) => {
            useAppStore().apontamentos_historico = apontamentos_filtrados;
          })
        }
      }
      catch (error) {
        console.error("Erro ao listar histórico de apontamentos:", error);
        throw new Error(error);
      }
    },
    set_tp_parada(apontamento: Apontamentos) {
      var _tp_parada = ""

      switch (apontamento.tipo) {
        case 'Parada':
          _tp_parada = 'Parada';
          break;
        case 'Setup':
          _tp_parada = 'Setup';
          break;
        case 'Manutenção':
          _tp_parada = 'Manutenção';
          break;
      }

      return _tp_parada;
    },
    setResourceMaquinas(maquinas) {
      this.resourceMaquinas = maquinas;
    },

    async listar_maquinas() {

      try {
        this.resourceMaquinas = [];

        const res = await frappe.call(
          "nxlite.nx_producao.page.apontamento_iot.controller.get_all_recurso",
          { today: 1 }
        );

        if (res.message && Array.isArray(res.message)) {
          res.message.forEach((maquina: any) => {
            const apontamentos: Apontamentos[] = [];

            if (Array.isArray(maquina.apontamentos)) {
              maquina.apontamentos.forEach((apontamento: Apontamentos) => {
                apontamentos.push({
                  name: apontamento.name,
                  nome_operador: apontamento.nome_operador,
                  recurso: apontamento.recurso,
                  desc_recurso: apontamento.desc_recurso,
                  dt_inicio: apontamento.dt_inicio,
                  dt_fim: apontamento.dt_fim,
                  ordem_de_producao: apontamento.ordem_de_producao,
                  status: apontamento.status,
                  observacao: apontamento.observacao,
                  tipo: apontamento.tipo,
                  serial: apontamento.serial,
                  motivo_de_parada: apontamento.motivo_de_parada,
                  desc_motivo_parada: apontamento.desc_motivo_parada,
                  origem_apontamento: apontamento.origem_apontamento,
                  turno: apontamento.turno,
                  status_sensor: apontamento.status_sensor,
                  motivo_de_parada_manutencao_setup: this.set_tp_parada(apontamento),
                  setup: apontamento.setup,
                  manutencao: apontamento.manutencao
                });
              });
            }

            const existingMachine = this.resourceMaquinas.find(m => m.id === maquina.name);

            if (!existingMachine) {
              this.resourceMaquinas.push({
                id: maquina.name,
                imagem: maquina.imagem,
                leitor: maquina.leitor,
                centro_de_custo: maquina.centro_de_custo,
                nome: maquina.nome,
                operador_padrao: maquina.operador_padrao,
                tipo_apontamento: maquina.tipo_apontamento,
                apontamentos: apontamentos,
                state_sensor: maquina.state_sensor,
                dt_atualizacao_sensor: maquina.dt_atualizacao_sensor
              });
            }

            if (useAppStore().maquina_sel.id) {
              const apontamentoAberto = maquina.apontamentos.find((apontamento: Apontamentos) => apontamento.status === "Aberto");

              if (apontamentoAberto) {
                useAppStore().set_apontamento_realtime(apontamentoAberto);

                useAppStore().apontamentos_historico = apontamentos.filter((apontamento: Apontamentos) => apontamento.status !== "Aberto");
              }
            }
          });
        } else {
          console.error("Resposta inesperada do servidor:", res);
        }
      } catch (error) {
        console.error("Erro ao listar máquinas:", error);
        throw new Error(error);
      }
    },

    update_recurso_realtime(recurso_doc: any) {
      if (useAppStore().maquina_sel.id == recurso_doc.recurso) {
        useAppStore().maquina_sel.state_sensor = recurso_doc.state_sensor;
      }
    },

    async update_apontamento_realtime(data) {
      console.log(data)


      if (data.recurso == useAppStore().maquina_sel.id) {
        const filteredApontamento = {
          name: data.name,
          nome_operador: data.nome_operador,
          recurso: data.recurso,
          desc_recurso: data.desc_recurso,
          dt_inicio: data.dt_inicio,
          dt_fim: data.dt_fim,
          ordem_de_producao: data.ordem_de_producao,
          status: data.status,
          observacao: data.observacao,
          tipo: data.tipo,
          serial: data.serial,
          motivo_de_parada: data.motivo_de_parada,
          desc_motivo_parada: data.desc_motivo_parada,
          origem_apontamento: data.origem_apontamento,
          turno: data.turno,
          status_sensor: data.status_sensor,
          setup: data.setup,
          manutencao: data.manutencao,
          motivo_de_parada_manutencao_setup: this.set_tp_parada(data)
        };

        if (!(data.status === 'Fechado')) {
          useAppStore().set_apontamento_realtime(filteredApontamento).then(() => {
            this.get_historico_apontamentos(data.recurso).then(async () => {
              this.get_historico_apontamentos(data.recurso)
            })
          });
        } else {
          console.log('update_or_unshift_apontamento_historico', filteredApontamento)
          useAppStore().update_or_unshift_apontamento_historico(filteredApontamento)
        }
      }

    },
  }, persist: { enabled: true }
});