<template>
    <v-dialog v-model="appStore.showModalObsDetalhes" transition="dialog-bottom-transition" fullscreen>
        <v-card>
            <v-toolbar color="primary">
                <v-btn @click="appStore.showModalObsDetalhes = false" color="secondary" variant="elevated"
                    prepend-icon="mdi-arrow-left-circle">VOLTAR</v-btn>

                <v-toolbar-title style="font-size: 16px;font-weight: bold;">DESENHOS</v-toolbar-title>

                <v-spacer></v-spacer>
            </v-toolbar>

            <v-tabs v-model="tab" bg-color="primary" color="white" slider-color="secondary" selected-class="color-tab"
                style="flex-shrink: 1; min-height: 30px">
                <v-tab value="anexos" style="width: 40%; min-width: 20%; max-width: 350px; height: 100%">ANEXOS</v-tab>
            </v-tabs>

            <v-tabs-window v-model="tab" v-if="appOrdemProducao.resourceFiles.length > 0"
                style="height: 100%; min-height: 550px; width: 100%">
                <div style="background-color: rgb(213, 213, 213); display: flex; height: calc(96vh - 64px);">
                    <div style="flex: 1; padding: 10px; height: 100%;">
                        <div style="overflow-y: auto; height: 100%;">
                            <v-list>
                                <v-list-item v-for="(item, index) in appOrdemProducao.resourceFiles" :key="index"
                                    @click="ShowFile(item)">
                                    <v-list-item-content>
                                        <v-list-item-title>{{ item.descricao }}</v-list-item-title>
                                        <v-list-item-subtitle>{{ item.arquivo }}</v-list-item-subtitle>
                                    </v-list-item-content>
                                </v-list-item>
                            </v-list>
                        </div>
                    </div>

                    <div style="flex: 3; padding: 10px; height: 100%;">
                        <div v-if="showpdf" style="height: 100%;">
                            <ejs-pdfviewer id="pdfViewer" :resourceUrl="resourceUrl" :documentPath="documentPath" :enableAccessibility="true"
                                style="height: 100%; width: 100%;">
                            </ejs-pdfviewer>
                        </div>
                        <div v-else style="height: 100%; display: flex; justify-content: center; align-items: center;">
                            <div v-if="documentPath" style="height: 100%; width: 100%;">
                                <img v-if="isImage" :src="documentPath" alt="Selected File"
                                    style="width: 100%; height: 100%; object-fit: contain;">
                                <div v-else id="viewer" style="width: 100%; height: 100%;" :model="documentPath"
                                    class="online_3d_viewer border-bottom border-left border-right"></div>
                            </div>
                            <div v-else-if="showError">
                                <v-alert color="error" icon="$error" title="Erro:"
                                    text="Não foi possível abrir o arquivo de imagem. Verifique se o formato é suportado e tente novamente.">
                                </v-alert>
                            </div>
                        </div>
                    </div>
                </div>
            </v-tabs-window>

            <v-div style="width: 100%;" v-else class="mt-4 pl-2 pr-2">
                <v-alert text="Nenhum desenho encontrado para a OS selecionada." type="warning"
                    color="secondary"></v-alert>
            </v-div>
        </v-card>
    </v-dialog>
</template>

<script>

import { useAppStore } from '../../../stores/app';

import { useAppOrdemProducao } from '../../../stores/appOrdemProducao';

import * as OV from 'online-3d-viewer';

import {
    GridComponent,
    ColumnsDirective,
    ColumnDirective,
    Page,
    Toolbar as GridToolbar,
    Edit,
    Sort,
} from "@syncfusion/ej2-vue-grids";

import {
    PdfViewerComponent, Toolbar,
    TextSearch

} from '@syncfusion/ej2-vue-pdfviewer';


export default {
    name: 'detalhes_os',
    components: {
        "ejs-pdfviewer": PdfViewerComponent,
        "ejs-grid": GridComponent,
        "e-columns": ColumnsDirective,
        "e-column": ColumnDirective,
    },
    provide: {
        PdfViewer: [
            TextSearch, Toolbar],
        grid: [Page, Edit, GridToolbar, Sort],
    },
    computed: {
        appStore() {
            return useAppStore();
        },
        appOrdemProducao() {
            return useAppOrdemProducao();
        }
    },
    watch: {
        file_select(new_value) {
            this.documentPath = window.location.origin + new_value.arquivo
            console.log('path', this.documentPath)
        }
    },
    data() {
        return {
            tab: 'anexos',
            file_select: {
                file: null,
                descricao: null
            },
            resourceUrl: `${window.location.origin}/assets/nxlite/ej2-pdfviewer-lib`,
            documentPath: "",
            showpdf: false,
            isImage: false,
            showError: false
        }
    },
    methods: {

        render_3d_viewer() {
            let parentDiv = document.getElementById('viewer');
            OV.Init3DViewerElements(parentDiv);
        },
        onRowDataBound(args) {
            args.row.style.backgroundColor = '#37474F';
        },
        ShowFile(data) {
            this.file_select = data;
            this.documentPath = window.location.origin + data.arquivo;
            console.log(this.documentPath, data)
            this.showpdf = data.arquivo.toLowerCase().endsWith('.pdf');
            this.isImage = /\.(jpg|jpeg|png|gif)$/i.test(data.arquivo);
            this.showError = false; // Reset the error flag

            if (!this.showpdf && !this.isImage) {
                setTimeout(() => {
                    this.render_3d_viewer();
                }, 1000);
            } else if (!this.documentPath) {
                this.showError = true; // Show error if documentPath is invalid
            }
        }
    }
}
</script>

<style>
.border-bottom {
    border-bottom: 2px solid #000;
    /* Ajuste a cor e a espessura conforme necessário */
}

.border-left {
    border-left: 2px solid #000;
    /* Ajuste a cor e a espessura conforme necessário */
}

.border-right {
    border-right: 2px solid #000;
    /* Ajuste a cor e a espessura conforme necessário */
}
</style>