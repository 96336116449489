import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_charts_recursos_por_status = _resolveComponent("charts_recursos_por_status")
  const _component_v_card = _resolveComponent("v-card")

  return (_openBlock(), _createBlock(_component_v_card, { height: "100%" }, {
    default: _withCtx(() => [
      _createVNode(_component_charts_recursos_por_status)
    ]),
    _: 1 /* STABLE */
  }))
}