import { Apontamentos, Apontamento } from './../app/common/interfaceApp';
import { defineStore } from 'pinia';
import { useAppStore } from './app';
import { useAppMotivoDeParada } from './appMotivosdeParada';

declare let frappe: any;

export const useAppApontamento = defineStore({
  id: 'appApontamento_iot',
  state: (): {
    showModal: boolean,
    titulo_modal: string,
    resourceApontamento: Partial<Apontamento>,
    showModalObs: boolean,
  } => ({
    showModal: false,
    titulo_modal: '',
    resourceApontamento: {
      name: null,
      nome_operador: null,
      recurso: null,
      desc_recurso: null,
      dt_inicio: null,
      dt_fim: null,
      ordem_de_producao: null,
      status: null,
      observacao: null,
      desc_motivo_parada: null,
      tipo: null,
      motivo_de_parada: null,
      motivo_de_parada_manutencao_setup: null,
    },
    showModalObs: false
  }),

  getters: {},
  actions: {
    set_apontamento(apontamento: Apontamento) {
      this.resourceApontamento = apontamento
    },

    selecionar_motivo_de_parada(mp: any){
      useAppMotivoDeParada().apontamento_name = mp.name
    },

    selecionar_funcionario(funcionario: any) {
      this.resourceApontamento.nome_operador = funcionario.nome
    },
    selecionar_op(op: any) {
      this.resourceApontamento.ordem_de_producao = op.name
    },

    
    async update_apontamento(name: any, data: any) {
      try {
        const response = await frappe.call({
          method: 'nxlite.nx_producao.page.apontamento_iot.controller.update_apontamento',
          args: {
            apt_name: name,
            changes: data
          }
        });
        return response; 
      } catch (error) {
        console.error('Erro ao atualizar apontamento:', error);
        throw error; 
      }
    },


    async abrir_apontamento(recurso: String) {
      const res = await frappe.call(
        "nxlite.nx_producao.page.apontamento_iot.controller.criar_novo_apontamento",
        { recurso: recurso }
      );

      if (res.message.length) {
        if (Array.isArray(res.message[0].apontamentos)) {
          res.message[0].apontamentos.forEach((apontamento: Apontamentos) => {
            const novoApontamento = {
              ...apontamento,
              name: apontamento.name,
              nome_operador: apontamento.nome_operador,
              recurso: apontamento.recurso,
              desc_recurso: apontamento.desc_recurso,
              dt_inicio: apontamento.dt_inicio,
              dt_fim: apontamento.dt_fim,
              ordem_de_producao: apontamento.ordem_de_producao,
              status: apontamento.status,
              observacao: apontamento.observacao,
              tipo: apontamento.tipo,
              serial: apontamento.serial,
              motivo_de_parada: apontamento.motivo_de_parada,
              desc_motivo_parada: apontamento.desc_motivo_parada,
              origem_apontamento: apontamento.origem_apontamento,
              turno: apontamento.turno,
              status_sensor: apontamento.status_sensor
            };
          });
        }

        const apontamentos: Apontamentos[] = [];

        useAppStore().maquina_sel.id = res.message[0].name
        useAppStore().maquina_sel.leitor = res.message[0].leitor
        useAppStore().maquina_sel.nome = res.message[0].nome
        useAppStore().maquina_sel.operador_padrao = res.message[0].operador_padrao
        useAppStore().maquina_sel.tipo_apontamento = res.message[0].tipo_apontamento
        useAppStore().maquina_sel.apontamentos = apontamentos

        const apontamentoAberto = apontamentos.find((apontamento: Apontamentos) => apontamento.status === "Aberto");

        if (apontamentoAberto) { useAppStore().set_apontamento_realtime(apontamentoAberto); }
        useAppStore().apontamentos_historico = apontamentos.filter((apontamento: Apontamentos) => apontamento.status !== "Aberto");
      }
    }
  }
})